.page-title-wrap {
    min-height: 350px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #ffffff14;
    border-bottom: 1px solid #ffffff14;
}

.page-title-wrap .col-12 {
    text-align: center;
    color: #fff;
}