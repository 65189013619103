.accordion {
    margin: 30px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.accordion__item {
    width: 100%;
    max-height: 53px;
    background-image: linear-gradient(180deg, #292929 0%,rgba(41, 41, 41, 0) 100% );
    color: #fff;
    overflow: hidden;
    cursor: pointer;
}

.accordion__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    position: relative;
}

.accordion__header:before{
    content: "";
    width: 5px;
    height: 100%;
    background-color: #f28920;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
}

.accordion__header h4 {
    transition: 0.2s ease-in-out;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
    letter-spacing: normal;
    font-family: 'Montserrat', sans-serif;
}

.accordion__header i {
    transition: 0.2s ease-in-out;
    transform-origin: center;
    margin-bottom: 0px;
}

.accordion__header:hover h4 {
    color: #f28920 !important;
}

.accordion__header:hover i {
    color: #f28920;
}

.accordion__content {
    padding: 20px 30px;
    position: relative;
}

.accordion__content .list li {
    list-style: disc;
    margin-bottom: 10px;
}

@media (max-width:600px) {
    h1 {
        font-size: 30px;
    }

    .accordion__header h4, 
    .accordion__content .list li {
        font-size: 13px;
    }
}