/* CSS Index
-----------------------------------

1. Theme default css
2. Header
3. Search modal
4. Breadcrumb
5. Slider
6. Brand
7. Product-area
8. About-us
9. Game Released
10. Game Manage
11. Featured-Games
12. Cta
13. Tournament
14. Just-gamers
15. Features
16. Fact
17. Shop
18. Upcoming-games
19. Game-overview
20. Blog
21. Pagination
22. Testimonial
23. Community
24. Contact
25. Newsletter
26. Footer
27. Preloader

*/

@import '../css/default.css';
@import '../css/bootstrap.min.css';
@import '../css/meanmenu.css';

/* 1. Theme default css */
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600&display=swap');

body {
	font-family: 'Orbitron', sans-serif;
	font-weight: normal;
	font-style: normal;
	font-size: 15px;
	font-weight: 500;
	color: #d4d4d4;
}

.img {
	max-width: 100%;
	transition: all 0.3s ease-out 0s;
}

.f-left {
	float: left
}

.f-right {
	float: right
}

.fix {
	overflow: hidden
}

a {
	text-decoration: none;
}

a,
.button {
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}

a:focus,
.btn:focus,
.button:focus {
	text-decoration: none;
	outline: none;
	box-shadow: none;
}

a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
	color: #2B96CC;
	text-decoration: none;
}

a,
button {
	color: #1696e7;
	outline: medium none;
}

button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
	outline: 0
}

.uppercase {
	text-transform: uppercase;
}

.capitalize {
	text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Orbitron', cursive;
	letter-spacing: .5rem;
	color: #fff;
	margin-top: 0px;
	font-style: normal;
	font-weight: 800;
	text-transform: normal;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: inherit;
}

h1 {
	font-size: 40px;
}

h2 {
	font-size: 35px;
}

h3 {
	font-size: 28px;
}

h4 {
	font-size: 22px;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 16px;
}

ul {
	margin: 0px;
	padding: 0px;
}

li {
	list-style: none
}

p {
	font-size: 15px;
	font-weight: 500;
	line-height: 28px;
	color: #fff;
	margin-bottom: 15px;
}

hr {
	border-bottom: 1px solid #eceff8;
	border-top: 0 none;
	margin: 30px 0;
	padding: 0;
}

label {
	color: #fff;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
}

*::-moz-selection {
	background: #444;
	color: #fff;
	text-shadow: none;
}

::-moz-selection {
	background: #444;
	color: #fff;
	text-shadow: none;
}

::selection {
	background: #444;
	color: #fff;
	text-shadow: none;
}

*::-moz-placeholder {
	color: #555555;
	font-size: 14px;
	opacity: 1;
}

*::placeholder {
	color: #555555;
	font-size: 14px;
	opacity: 1;
}

.theme-overlay {
	position: relative
}

.theme-overlay::before {
	background: #1696e7 none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	opacity: 0.6;
	position: absolute;
	top: 0;
	width: 100%;
}

/* .separator {
	border-top: 1px solid #f2f2f2
} */

/* button style */
.btn {
	color: #1c1121;
	cursor: pointer;
	display: inline-block;
	font-size: 15px;
	font-weight: 700;
	margin-bottom: 0;
	padding: 15px 30px;
	text-align: center;
	text-transform: uppercase;
	touch-action: manipulation;
	transition: all 0.4s ease 0s;
	vertical-align: middle;
	white-space: nowrap;
	position: relative;
	z-index: 1;
	letter-spacing: .1rem;
}

.btn:hover {
	box-shadow: inset 0 0 0 2em #fff;
	color: #edcb74;
}

.btn i {
	margin-right: 5px;
}

.btn.transparent-btn {
	border: 2px solid #9f999d;
	background: transparent;
	color: #fff;
}

.btn.transparent-btn:hover {
	border: 2px solid #e4a101;
	color: #1c1121;
}

.btn.transparent-btn::before {
	display: none;
}

/* scrollUp */
#scrollUp {
	background: #E4A101;
	height: 40px;
	width: 40px;
	right: 50px;
	bottom: 50px;
	color: #1c1121;
	text-align: center;
	border-radius: 0;
	font-size: 22px;
	line-height: 38px;
}

#scrollUp:hover {
	background: #444;
	color: #fff;
}


/* 2. Header */

.main-wrapper {
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top;
}

.line-bottom {
	opacity: .5;
	position: absolute;
    bottom: -30px;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 58px;
    background-size: cover;
    background-position: center;
}

.slider-content h6,
.slider-content h2 span,
.section-title h2 span,
.latest-games-content h4 a:hover,
.latest-games-content h4 span,
.latest-games-content p span,
.fw-link ul li a:hover,
.copyright-text p a {
	color: #edcb74;
}

.main-header {
	position: relative;
}

.main-menu nav {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.navbar-wrap {
	display: flex;
	flex-grow: 1;
}

.navbar-wrap ul {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: auto;
	margin-bottom: 0;
	gap: 5px;
}

.navbar-wrap ul li {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

}

.navbar-wrap ul li a {
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	color: #fff;
	padding: 43px 26px;
	min-height: 44px;
	display: block;
	line-height: 1;
	position: relative;
	z-index: 1;
	letter-spacing: .1rem !important;
	border: 6px solid transparent;
}

.navbar-wrap ul li.show a::before,
.navbar-wrap ul li a::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	border-radius: 5px;
	z-index: -1;
	right: 0;
	margin: 0 auto;
	opacity: 0;
}

.navbar-wrap ul li:hover>a {
	color: #edcb74;
}

.mint-wrap {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 20px;
    border-color: #278cce;
    box-shadow: 0 0 10px #278cce;
    padding: 30px;
    overflow: hidden;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
	background-size: cover;
    background-repeat: no-repeat;
    min-height: 50vh;
}

.mint-wrap img{
	border: 1px solid #edcb74;
    box-shadow: 0 0 10px #edcb7496;
	border-radius: 10px;
	backdrop-filter: blur(10px);
	padding: 10px;
}

.mint-wrap button {
    width: 200px;
}

.market-page{
	min-height: 50vh;
}

.navbar-wrap ul li.show,
.navbar-wrap ul li:hover>a,
.navbar-wrap ul li:hover>div:not(.mtz-profile_box) {
	background-position: center;
    border: 6px solid transparent;
    background: linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
}

.navbar-wrap ul li.show>a {
	color: #1c1121;
}

.navbar-wrap ul li.active>a::before {
	opacity: 1;
}

.sticky-menu {
	left: 0;
	margin: auto;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 99;
	-webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
	animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
	-webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
	box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
	border-radius: 0;
	padding: 0;
}

.header-style-four .menu-style-two .logo img {
	width: 180px;
}

.custom-container {
	max-width: 1330px;
}

.custom-container-two {
	max-width: 1430px;
}

.header-style-four .menu-style-two {
	padding: 0;
	border-radius: 0;
	background-position: unset;
	background-size: cover;
	position: relative;
}

.header-style-four {
	position: fixed;
	box-shadow: 0 0 5px #edcb74;
	left: 0;
	right: 0;
	top: 0;
	z-index: 10;
}

.header-four-wrap {
	background: #0009;
	padding: 5px 0;
}

.header-four-wrap .menu-style-two .navbar-wrap>ul>li>a {
	padding: 12px 23px;
	display: flex;
	align-items: center;
}

.header-four-wrap.sticky-menu {
	position: fixed;
	border-top: none;
}

.header-user-login>a i {
	background: #edcb74;
	border-radius: 5px;
}

/* .navbar-wrap ul li a::before {
	border: 2px solid transparent;
	background:
		linear-gradient(#000 0 0) padding-box,
		linear-gradient(to right, #edcb74, #F9CA1A) border-box;
	border-radius: 99px;
	transition: .4s all;
} */

.btn-connectWallet {
	padding: 7px 15px;
	letter-spacing: .1rem !important;
	color: #edcb74;
	box-shadow: rgb(224 224 224 / 15%) 0px 4px 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ffffff42;
}

.btn-connectWallet:hover{
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    -webkit-animation: slideUp 500ms ease forwards;
    animation: slideUp 500ms ease forwards;
    color: #f0eb8d;
    background: linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
}

/* 5. Slider */
.slider-bg {
	min-height: 800px;
	text-align: center;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.slider-bg .slider-content {
	width: 800px;
	padding: 1rem;
	border-radius: 20px;
	background-color: #0009;
}

.slider-content h2 {
	font-size: 36px;
	font-weight: 800;
	line-height: .9;
	margin-bottom: 0;
	text-transform: uppercase;
	color: #fff;
	word-break: break-all;
	background: -webkit-linear-gradient(left, #FA5315, #edcb74, #FA5315);
	background-size: 1600px 200px;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation-duration: 3s;
	animation-name: animationgradienttitle;
	animation-iteration-count: infinite;
	animation-fill-mode: forwards;
	/* -webkit-text-fill-color: transparent;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #fff; */
	/* text-shadow: 0 6px #f8a41b, 5px 6px #edcb7475; */
	/* text-shadow:
		0 0 7px #f8a41b,
		0 0 10px #f8a41b,
		0 0 21px #f8a41b,
		0 0 42px #f8a41b,
		0 0 82px #f8a41b,
		0 0 92px #f8a41b,
		0 0 102px #f8a41b,
		0 0 151px #f8a41b; */
}

@keyframes animationgradienttitle {
	0% {
		background-position: 0 1600px;
	}
	100% {
		background-position: 1600px 0;
	}
  }

.slider-content-img {
	width: 200px;
	display: block;
	margin: 0 auto;
	margin-bottom: 30px;
}

.slider-content p {
	font-weight: 400;
	color: #fff;
	width: 100%;
	margin: 0 auto 20px;
	font-size: 14px;
	line-height: 1.6;
}

.slider-img {
	position: absolute;
	right: 135px;
	bottom: 0;
}

.slider-img.slider-img2 {
	right: 295px;
	z-index: -1;
}

.slider-circle-shape {
	position: absolute;
	top: 50%;
	opacity: .3;
	left: 10%;
	transform: translateY(-50%);
}

.gameplays-circle-shape {
	z-index: -1;
	left: 50%;
	transform: translate(-50%, -50%);
}

.team-circle-shape {
	z-index: 1;
	left: 50%;
	transform: translate(-50%, -50%);
}

.team-circle-shape img {
	width: 600px;
}

.rotateme {
	-webkit-animation-name: rotateme;
	animation-name: rotateme;
	-webkit-animation-duration: 50s;
	animation-duration: 50s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
}

@keyframes rotateme {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes rotateme {
	from {
		-webkit-transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
	}
}

.slider-active .slick-arrow {
	position: absolute;
	top: 50%;
	left: 120px;
	transform: translateY(-50%);
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	z-index: 1;
}

.slider-active .slick-next {
	right: 120px;
	left: auto;
}

.third-banner-bg {
	background-position: center center;
	background-size: cover;
	position: relative;
	padding: 150px 0 130px;
	margin-top: -20px;
}

.wrap-btn {
	gap: 20px;
}

.rotated-btn {
	font-size: 16px;
	color: #edcb74;
	box-shadow: rgb(224 224 224 / 15%) 0px 4px 12px;
	padding: 10px 15px;
	transition: all 500ms ease;
}

.rotated-btn:hover {
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    animation: slideUp 500ms ease forwards;
	color: #f0eb8d;
    background: linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
}

.third-banner-content h2 {
	font-size: 120px;
	text-transform: uppercase;
	line-height: .8;
	font-family: 'Orbitron', cursive;
	font-weight: normal;
	margin-bottom: 14px;
}

.blood {
	width: 30rem;
	height: 75px;
	background-repeat: no-repeat;
	background-size: 100%;
	position: absolute;
	top: -5px;
	z-index: -1;
	left: 0px;
}

.third-banner-content h6 {
	text-transform: uppercase;
	font-family: 'Orbitron', cursive;
	font-weight: normal;
	letter-spacing: 10px;
	line-height: 1.6;
	font-size: 20px;
	margin-bottom: 30px;
}

.third-banner-img {
	text-align: center;
	margin-bottom: -40px;
	position: relative;
	z-index: 1;
}

.third-banner-img::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: -20px;
	height: 20px;
	width: 1065px;
	background: #edcb74;
	margin: 0 auto;
	right: 0;
	opacity: .35;
}

.third-banner-content {
	position: relative;
	z-index: 1;
}

.home-four-slider .custom-container-two {
	position: relative;
}

.home-four-slider .slider-img {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
	bottom: auto;
}

/* Video */

.video-lightbox {
	z-index: 999;
	height: 100vh;
	position: fixed;
	background-color: rgba(12, 12, 12, .47843137254901963);
	transition: all .5s ease-in-out;
	-webkit-backdrop-filter: blur(12px);
	backdrop-filter: blur(12px);
	inset: 0;
}

.video-lightbox div {
    background-color: transparent !important;
}

.video {
	position: relative;
	cursor: pointer;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px;
	background-position: center;
}

.line_title {
    width: 190px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    margin-bottom: 15px;
}


.line_title_2 {
    width: 400px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    margin-bottom: 15px;
}

.video img {
	width: 100%;
	height: 100%;
}

.video .lightbox {
	display: block;
	position: relative;
}

.video .play {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.video .play svg {
	color: #edcb74;
	transition: .2s ease-in-out;
}

.video .play:hover svg {
	transform: scale(1.2);
}

/* Characters */

.banner-position img {
	width: 100%;
	height: 100%;
	object-fit: fill;
}

.characters-wrapper {
	background-attachment: fixed;
	background-position: center;
}

/* .characters-wrapper:before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: #0b0b0b99;
    z-index: -1;
} */

.character-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 40px;
}

.character-wrap .character-left {
	flex: 0 0 400px;
	position: relative;
}

.character-wrap .character-right {
	flex: 0 0 500px;
}

.character-wrap .character-left>img {
	width: 100%;
	object-fit: contain;
}

.character-circle-shape {
	left: 50%;
	transform: translate(-50%, -50%);
	right: unset;
}

.character-circle-shape img {
	width: 400px;
}

.character-right h4 {
	font-size: 32px;
	color: #fff;
}

.character-bullet,
.features-bullet {
	width: 40px !important;
	height: 40px !important;
	border-radius: 99px !important;
	display: flex !important;
	align-items: center;
	justify-content: center;
	font-family: 'Montserrat', cursive;
	letter-spacing: .1rem !important;
	font-size: 20px;
	font-weight: 500;
	background-color: #edcb74;
}

.roadmap_slider_thumb .swiper-pagination-bullet,
.team-slide .swiper-pagination-bullet {
	background-color: #edcb74;
	width: 20px !important;
	height: 20px !important;
	display: flex !important;
	align-items: center;
	justify-content: center;
	font-family: 'Montserrat', cursive;
	letter-spacing: .1rem !important;
	font-size: 20px;
	font-weight: 800;
}

.characters-slider .swiper-pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	bottom: 50px !important;
}

.roadmap_slider_thumb .swiper-pagination,
.team-slide .swiper-pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	bottom: 0px !important;
}

.character-bullet.swiper-pagination-bullet-active,
.features-bullet.swiper-pagination-bullet-active,
.roadmap_slider_thumb .swiper-pagination-bullet-active,
.team-slide .swiper-pagination-bullet-active {
	background-color: #edcb74;
}

.features_slider_thumb .swiper-pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	bottom: 0px !important;
}

.character-list {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	margin-top: 50px;
	gap: 50px;
}

.character-list-item {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.character-item-left img {
	width: 300px;
	height: 400px;
	object-fit: contain;
}

.character-item-wrap {
	border: 6px solid transparent;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

/* .guns-slider .latest-games-items img {
    clip-path: polygon(5% 0, 95% 0, 100% 5%, 100% 95%, 95% 100%, 5% 100%, 0 95%, 0 5%);
} */

.guns_slider_thumb .swiper-pagination-bullet {
	width: 15px;
	height: 15px;
	border-radius: 0;
	background-color: #edcb74;
}

.guns_slider_thumb .swiper-slide-thumb-active .latest-games-items {
	transition: .4s ease-in-out;
	opacity: 1 !important;
}

.guns_slider_thumb {
    margin-bottom: 10px;
}

.social-item {
    clip-path: polygon(24px 0,100% 0,100% 418px,761px 100%,0 100%,0 24px);
    -webkit-border-radius: 34px 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

.gun-item-slide {
	position: relative;
}

.shape-top {
	position: absolute;
	height: 20px;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	opacity: 1;
}

.shape-top:before {
	content: "";
	width: 20px;
	height: 20px;
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	border-top: 1px solid #edcb74;
	border-left: 1px solid #edcb74;
}

.shape-top:after {
	content: "";
	width: 20px;
	height: 20px;
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	border-top: 1px solid #edcb74;
	border-right: 1px solid #edcb74;
}

.shape-bottom {
	position: absolute;
	height: 20px;
	width: 100%;
	bottom: 0;
	left: 0;
	right: 0;
}

.shape-bottom:before {
	content: "";
	width: 20px;
	height: 20px;
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	border-bottom: 1px solid #edcb74;
	border-left: 1px solid #edcb74;
}

.shape-bottom:after {
	content: "";
	width: 20px;
	height: 20px;
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	border-bottom: 1px solid #edcb74;
	border-right: 1px solid #edcb74;
}

.gun-attribute-wrap {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}

.gun-attribute {
	margin-top: 20px;
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: center;
}

.gun-attribute h3 {
	font-size: 18px;
	letter-spacing: 2px;
	margin-bottom: 0;
	position: absolute;
	top: -26px;
	left: 20px;
	font-family: 'Montserrat', sans-serif;
}

.gun-attribute-left img {
	width: 40px;
	height: 40px;
}

.gun-attribute-left {
	flex: 0 0 40px;
}

.gun-attribute-right {
	flex: 0 0 400px;
	position: relative;
}

.gun-content {
	text-align: center;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.gun-attribute .number {
	font-size: 24px;
	font-weight: 600;
	background-size: cover;
	background-position: left;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	width: 400px;
}

.gun-attribute .number .current {
	margin-right: 20px;
	font-size: 18px;
	color: #fff;
}

.gun-attribute-progress {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: left;
	height: 100%;
}

.gun-attribute-progress-wrap {
	flex: 0 0 300px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: left;
	margin-left: 20px;
	height: 23px;
}

.gun-item-slide-name {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
	text-align: center;
}

.gun-item-slide-name h4 {
	color: #edcb74;
	font-family: 'Montserrat', sans-serif;
	letter-spacing: normal;
}

.gun-item-slide-name p span {
	color: #edcb74;
}

.gun-attribute .number .total {
	border-top: 1px solid #ffffff61;
	color: #edcb74;
}

.guns-slider .swiper-button-prev {
	width: 40px;
	height: 40px;
	background: #edcb74a6;
}

.guns-slider .swiper-button-prev:after {
	font-size: 16px;
	font-weight: 600;
}

.guns-slider .swiper-button-next {
	width: 40px;
	height: 40px;
	background: #edcb74a6;
}

.guns-slider .swiper-button-next:after {
	font-size: 16px;
	font-weight: 600;
}

/* gallery */

.gallery {
	display: grid;
	grid-template-columns: auto auto auto auto;
	gap: 20px;
}

.gallery .gallery-item img {
	width: 100%;
	height: 200px;
	object-fit: cover;
	transition: all 0.5s ease-in-out !important;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
}

/* features */

.features-wrap {
	background-attachment: fixed;
}

.features-wrap:before {
	content: "";
	display: block;
	position: absolute;
	inset: 0;
	background-color: #0b0b0b99;
	z-index: -1;
}

.features_slider_thumb {
	margin-top: 20px;
}

.features-games-items {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	padding: 10px;
    background-position: center;
    border: 6px solid transparent;
    background: linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%;
    background-repeat: no-repeat;
    background-size: 10rem 3rem;
}

.features-games-left {
	position: relative;
}

.features-games-thumb img {
	width: 100%;
	height: 215px;
	object-fit: contain;
}

.features-games-right {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 40px;
	text-align: center;
}

.features-games-right h4 {
	font-size: 24px;
	color: #edcb74;
	letter-spacing: normal;
}

/* team */

.item-team img {
	width: 100%;
	border-radius: 50%;
	height: 130px;
	object-fit: contain;
	border-radius: 99%;
	border: 1px solid #edcb74;
}

.item-team .avatar {
	text-align: center;
	width: 130px;
	margin: 0 auto;
	margin-bottom: 20px;
	position: relative;
}

.item-team {
	padding: 20px 10px;
	text-align: center;
	overflow: hidden;
	margin-top: 25px;
	margin-bottom: 45px;
	padding: 10px;
    background-position: center;
    border: 6px solid transparent;
    background: linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%;
    background-repeat: no-repeat;
    background-size: 3rem 3rem;
}

.item-team-text {
	position: relative;
}

.item-team .name {
	font-size: 20px;
	color: #edcb74;
	font-weight: 600;
	font-family: 'Montserrat', sans-serif;
	letter-spacing: .1rem !important;
}

.item-team-text span {
	display: block;
	min-height: 44px;
}

/* items */

.items_wrap {
	display: grid;
	grid-template-columns: 18% 18% 18% 18% 18%;
	gap: 20px;
	justify-content: space-between;
}

.latest-item-img img {
	width: 100%;
	height: 150px;
	object-fit: cover;
}

.latest-item-img {
	display: flex;
	align-items: center;
	justify-content: center;
}

.latest-item-img .item-thumb-name {
	position: absolute;
	inset: 0;
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	font-size: 18px;
	text-align: center;
	font-family: 'Montserrat', sans-serif;
	letter-spacing: normal;
	opacity: 0;
	transition: .4s all;
}

.nft-item:hover .latest-item-img .item-thumb-name {
	-webkit-backdrop-filter: blur(12px);
	backdrop-filter: blur(12px);
	opacity: 1;
}

/* 7. Product-area */
.section-title>span {
	display: block;
	line-height: 1;
	font-size: 14px;
	font-weight: 700;
	color: #c8c8c8;
	margin-bottom: 15px;
	text-transform: uppercase;
}

.section-title h2 {
	font-size: 45px;
	font-weight: 800;
	margin-bottom: 0;
	line-height: 1;
	letter-spacing: -2px;
}

.latest-games-items {
	position: relative;
}

.latest-games-thumb img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	cursor: pointer;
}

.latest-games-content {
	width: 100%;
	padding: 35px 30px;
	z-index: 1;
}

.latest-games-content h4 {
	font-size: 32px;
	margin-bottom: 13px;
	line-height: 1;
}

.latest-games-content p {
	line-height: 1;
	margin-bottom: 0;
	color: #c9c9c9;
	text-transform: capitalize;
	font-weight: 600;
	font-size: 13px;
}

.latest-games-active.owl-carousel .owl-nav,
.testimonial-active.owl-carousel .owl-nav {
	position: absolute;
	right: 0;
	top: -85px;
	z-index: 1;
}

.latest-games-active.owl-carousel .owl-nav button,
.testimonial-active.owl-carousel .owl-nav button {
	width: 37px;
	height: 33px;
	color: #bebac0;
	border: 3px solid #bebac0;
	display: inline-block;
	transition: .3s linear;
}

.latest-games-active.owl-carousel .owl-nav button.owl-next,
.testimonial-active.owl-carousel .owl-nav button.owl-next {
	margin-left: 10px;
}

.latest-games-active.owl-carousel .owl-nav button:hover,
.product-active .slick-arrow:hover,
.testimonial-active.owl-carousel .owl-nav button:hover {
	border-color: #e4a101;
}

.home-four-title h2 {
	text-transform: uppercase;
	font-size: 36px;
	letter-spacing: 0;
}

.home-four-title.black-title h2 {
	color: #1c1121;
}

.home-four-latest-games .latest-games-content h4 {
	font-size: 20px;
	text-transform: uppercase;
}

/* 8. About-us */
.about-img {
	padding: 15px;
	background-color: rgba(245, 58, 7, 0);
	box-shadow: 0px 5px 12.09px 0.91px rgba(0, 0, 0, 0.23);
	position: relative;
}

.about-img>img {
	width: 100%;
	box-shadow: 0px 5px 12.09px 0.91px rgba(0, 0, 0, 0.23);
	border-radius: 6px;
}

.about-img .popup-video {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}

.title-style-two,
.title-style-three {
	padding-bottom: 35px;
	position: relative;
}

.title-style-two::before,
.title-style-three::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 270px;
	height: 13px;
	background-repeat: no-repeat;
}

.title-style-three h2 {
	color: #1c1121;
	text-transform: uppercase;
	font-size: 36px;
	margin-bottom: 10px;
}

.title-style-three p {
	color: #6c6c6c;
	margin-bottom: 0;
}

.about-content {
	padding-right: 70px;
}

.about-content p:first-child {
	margin-bottom: 20px;
}

.about-content p:nth-child(2) {
	margin-bottom: 55px;
}

.inner-about-area .title-style-three {
	padding-bottom: 30px;
}

.inner-about-area .title-style-three h2 {
	margin-bottom: 0;
}

.inner-about-content h5 {
	font-size: 22px;
	color: #f2a900;
	margin-bottom: 20px;
}

.inner-about-content p {
	color: #6c6c6c;
	margin-bottom: 12px;
}

.inner-about-content p:nth-child(3) {
	margin-bottom: 35px;
}

.inner-about-img img {
	margin-left: 115px;
}

.inner-about-img img:nth-child(2) {
	margin-left: 10px;
	margin-top: -315px;
	box-shadow: 0px 2px 21px 0px rgba(17, 8, 23, 0.1);
	border-radius: 10px;
}

.inner-about-img img:nth-child(3) {
	margin-top: -200px;
	margin-left: -65px;
}

.inner-about-area {
	position: relative;
	padding-bottom: 120px;
	padding-top: 105px;
}

.inner-about-shape {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: -1;
}

.third-about-bg {
	background-position: center;
	background-size: 100% 100%;
	position: relative;
	overflow: hidden;
	z-index: 1;
}

.html5-video-player .ytp-show-cards-title {
	display: none;
}

.video-full img {
	width: 100%;
}

.video-full {
	position: relative;
	width: 800px;
	margin: 0 auto;
	border: 6px solid transparent;
	padding: 10px;
	background-position: center;
	border: 6px solid transparent;
	background: linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%;
    background-repeat: no-repeat;
    background-size: 3rem 3rem;
}

.video-full .play {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
	color: #edcb74;
}

.third-about-img .shadow {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: -1;
}

.third-about-img img{
	width: 100%;
}

.third-title-style h2 {
	font-family: 'Orbitron', cursive;
	text-transform: uppercase;
	font-weight: normal;
	font-size: 48px;
	line-height: 1;
	margin-bottom: 10px;
	letter-spacing: 3px;
	color: #fff;
	background: -webkit-linear-gradient(#eee, #edcb74);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.third-title-style.text-center .blood {
	left: 50%;
	top: 10px;
	transform: translateX(-50%);
	display: inline-block;
}

.third-title-style .inner {
	position: relative;
	margin-bottom: 30px;
}

.third-title-style .vertical-title {
	font-family: 'Orbitron', cursive;
	text-transform: uppercase;
	font-weight: normal;
	font-size: 16px;
	position: absolute;
	transform: rotate(-90deg);
	left: -50px;
	margin-bottom: 0;
	top: 65px;
}

.third-title-style .inner p {
	font-size: 16px;
	margin-bottom: 0;
}

.my-info-box {
	text-align: center;
}

.third-section-title h2 {
	font-size: 40px;
	text-transform: uppercase;
	font-family: 'Orbitron', cursive;
	font-weight: normal;
	margin-bottom: 0;
}

.third-section-title h2 span {
	color: #edcb74;
}

.third-section-title {
	padding-bottom: 25px;
	position: relative;
}

.third-section-title::after {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	/* background-image: url(../img/images/section_line.png); */
	width: 120px;
	height: 8px;
	margin: 0 auto;
}

.my-info-box {
	text-align: center;
	background: #18191f;
	box-shadow: 0px 3px 24px 0px rgba(55, 17, 2, 0.52);
	position: relative;
}

.my-info-box-top {
	/* background-image: url(../img/bg/my_info_box_bg.png); */
	min-height: 48px;
	width: 100%;
	background-position: center;
	position: relative;
	display: inline-block;
	z-index: 1;
	margin-top: -7px;
}

.my-info-box-top h6 {
	font-size: 14px;
	text-transform: uppercase;
	margin-bottom: 0;
	color: #dcdcdc;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-family: 'Poppins', sans-serif;
	transition: .3s linear;
}

.my-info-box-content {
	min-height: 140px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 25px 30px;
}

.mt-award img {
	max-width: 100%;
}

.my-info-box-top .info-box-top-hover {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: .3s linear;
	opacity: 0;
	z-index: -1;
}

.my-info-box:hover .my-info-box-top .info-box-top-hover {
	opacity: 1;
}

.my-info-box:hover .my-info-box-top h6 {
	color: #fff;
}

.my-info-social ul {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.my-info-social ul li {
	margin: 5px 6px;
}

.my-info-social ul li a {
	display: block;
	background: #202020;
	padding: 10px 20px;
	line-height: 1;
	color: #d3d3d3;
	font-size: 12px;
	font-weight: 600;
	border-radius: 30px;
}

.my-info-social ul li a i {
	margin-right: 5px;
	color: #edcb74;
	font-weight: 400;
}

.my-clan-wrap {
	display: flex;
	align-items: center;
}

.clan-logo {
	margin-right: 20px;
}

.my-clan-info {
	text-align: left;
}

.my-clan-info h4 {
	font-size: 20px;
	text-transform: uppercase;
	font-family: 'Orbitron', cursive;
	font-weight: normal;
	line-height: 1;
	margin-bottom: 8px;
}

.my-clan-info>span {
	display: block;
	text-transform: uppercase;
	color: #dcdcdc;
	font-size: 12px;
	font-weight: 700;
}

.my-clan-info h4 span {
	color: #edcb74;
}

/* Live-match */
.home-four-title.text-center h2 {
	position: relative;
}

.home-four-title.text-center h2::after {
	content: "";
	display: block;
	/* background-image: url(../img/images/title_bar04.png); */
	width: 126px;
	height: 13px;
	margin: 17px auto 0;
}

.live-match-wrap {
	position: relative;
}

.live-match-wrap>img {
	max-width: 100%;
	border-radius: 5px;
	box-shadow: 0px 5px 12.09px 0.91px rgba(1, 31, 87, 0.19);
}

.live-match-wrap .popup-video {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}

.live-match-time>span {
	display: block;
	max-width: 264px;
	background: #e8a301;
	color: #fff;
	text-transform: uppercase;
	padding: 4px 20px;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 2px;
	margin: 0 auto 80px;
}

.live-match-time .time {
	font-size: 70px;
	color: #1c1121;
	line-height: 1;
	margin-bottom: 0;
	font-family: 'Poppins', sans-serif;
	padding: 0 50px;
	display: inline-block;
	position: relative;
}

.live-match-time .time::before,
.live-match-time .time::after {
	content: "";
	position: absolute;
	left: -50px;
	top: 50%;
	transform: translateY(-50%);
	width: 52px;
	height: 1px;
	background: #d3d1d1;
}

.live-match-time .time::after {
	right: -50px;
	left: auto;
}

.live-match-time .live-overlay-text {
	font-size: 378px;
	text-transform: uppercase;
	color: #f6f5f5;
	font-weight: 700;
	letter-spacing: -25px;
	position: absolute;
	top: 0;
	line-height: .8;
	margin-bottom: 0;
	z-index: -1;
	left: 50%;
	transform: translateX(-50%);
}

.live-match-team {
	position: relative;
}

.live-match-team::before {
	content: "";
	position: absolute;
	width: 566px;
	height: 560px;
	right: 35px;
	top: -140px;
	z-index: -1;
}

.live-match-team.right::before {
	left: 35px;
	right: auto;
}

.live-match-team.right {
	text-align: right;
}

/* 9. Game Released */
.released-game-item {
	display: flex;
	align-items: center;
	background: #fff;
	box-shadow: 0px 1px 13px 0px rgba(128, 18, 40, 0.1);
	position: relative;
	z-index: 1;
	padding: 20px 20px 20px;
	margin: 50px 0;
}

.released-game-img {
	margin-right: 35px;
	position: relative;
	z-index: 2;
}

.released-game-item-bg {
	/* background-image: url(../img/bg/released_slide_bg.jpg); */
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-position: center;
	background-size: cover;
	z-index: -1;
	opacity: .05;
}

.released-game-rating {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}

.released-game-rating h5 {
	font-size: 16px;
	font-weight: 700;
	color: #413b43;
	font-family: 'Poppins', sans-serif;
	margin-bottom: 0;
	margin-right: 10px;
}

.released-rating {
	display: flex;
	font-size: 14px;
	color: #e9a301;
	align-items: center;
}

.released-game-content h4 {
	margin-bottom: 30px;
	line-height: 50px;
	font-size: 20px;
	font-weight: 700;
	color: #1c1121;
	font-family: 'Poppins', sans-serif;
	position: relative;
	display: inline-block;
	z-index: 1;
}

.released-game-content h4::before {
	content: "";
	position: absolute;
	right: -50px;
	top: 0;
	height: 100%;
	width: 324px;
	background-repeat: no-repeat;
	z-index: -1;
}

.released-game-content h4>span {
	color: #fff;
}

.released-game-list ul li {
	display: block;
	font-size: 14px;
	font-weight: 500;
	color: #6c6c6c;
	margin-bottom: 10px;
}

.released-game-list ul li:last-child {
	margin-bottom: 0;
}

.released-game-list ul li span {
	color: #413b43;
	font-weight: 600;
	margin-right: 20px;
}

.released-game-content p {
	font-size: 14px;
	font-weight: 500;
	color: #6c6c6c;
	margin-bottom: 30px;
}

.released-game-content .btn {
	font-size: 14px;
	padding: 16px 26px;
}

.released-game-nav-item {
	margin: 10px 0;
}

.released-game-nav-item img {
	width: 100%;
	box-shadow: 0px 1px 13px 0px rgba(128, 18, 40, 0.1);
	cursor: pointer;
}

.released-game-active .slick-dots {
	position: absolute;
	left: 85px;
	bottom: 100px;
	z-index: 1;
	display: flex;
	align-items: center;
}

.released-game-active .slick-dots li {
	line-height: 0;
	margin: 0 15px;
}

.released-game-active .slick-dots li button {
	text-indent: -999999px;
	border: none;
	padding: 0;
	height: 8px;
	width: 8px;
	background: #fff;
	border-radius: 50%;
	position: relative;
	cursor: pointer;
	transition: .3s linear;
}

.released-game-active .slick-dots li button::before {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	height: 20px;
	width: 20px;
	border: 1px solid #e4a101;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	transition: .3s linear;
	opacity: 0;
}

.released-game-active .slick-dots li.slick-active button::before {
	opacity: 1;
}

.released-game-active .slick-dots li.slick-active button {
	background: #e4a101;
}

/* 10. Game Manage */
.title-style-two.text-center::before,
.title-style-three.text-center::before {
	right: 0;
	margin: 0 auto;
}

.game-mange-bg {
	/* background-image: url(../img/bg/game_manage_bg.jpg); */
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
}

.coming-match-team {
	display: flex;
	align-items: first baseline;
	justify-content: space-between;
	text-align: center;
	padding: 30px 35px 40px;
}

.coming-match-item {
	border-top: 5px solid #e4a101;
	background-color: rgb(33, 19, 44);
	box-shadow: 0px 5px 12.09px 0.91px rgba(0, 0, 0, 0.34);
}

.match-team-name a {
	font-size: 14px;
	text-transform: uppercase;
	color: #d7d5d5;
	font-weight: 800;
	display: block;
	line-height: 1;
	margin-top: 20px;
}

.coming-match-status {
	margin: 0 25px;
}

.coming-match-info {
	padding: 35px 35px;
	text-align: center;
	background: #1b1024;
}

.coming-match-info h5,
.tournament-schedule-content h3 {
	font-size: 18px;
	line-height: 1;
	margin-bottom: 10px;
	text-transform: uppercase;
}

.coming-match-info h5 span,
.tournament-schedule-content h3 span,
.tournament-schedule-content h3 a:hover {
	transition: .3s linear;
}

.match-rating i {
	font-size: 12px;
	color: #e4a101;
}

.match-rating {
	line-height: 1;
}

.match-info-action .btn {
	font-size: 12px;
	padding: 17px 17px;
}

.match-info-action {
	margin-top: 30px;
}

.match-info-action .transparent-btn {
	padding: 15px 17px;
	margin-left: 25px;
}

.coming-match-time .coming-time {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #20132b;
	padding: 20px 35px;
}

.coming-match-time .time-count span {
	display: block;
	text-align: center;
	font-size: 19px;
	font-weight: 800;
	line-height: 1;
	margin-bottom: 5px;
}

.coming-match-time .time-count {
	font-size: 11px;
	font-weight: 800;
	text-transform: uppercase;
	padding-right: 22px;
	margin-right: 22px;
	position: relative;
}

.coming-match-time .time-count:last-child {
	padding-right: 0;
	margin-right: 0;
}

.coming-match-time .time-count::before {
	content: ":";
	position: absolute;
	right: -2px;
	top: 50%;
	transform: translateY(-50%);
	font-size: 22px;
	color: #5e5e5e;
	font-weight: 500;
}

.coming-match-time .time-count:last-child::before {
	display: none;
}

/* 11. Featured-Games */

.features_list {
	gap: 30px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

.featured-game-item {
	box-shadow: 0px 1px 13px 0px rgba(12, 11, 15, 0.32);
	position: relative;
	z-index: 1;
}

.featured-game-item::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(233, 163, 1, 0.749);
	transition: .3s linear;
	opacity: 0;
}

.featured-game-thumb img {
	width: 100%;
}

.featured-game-content {
	position: absolute;
	left: 30px;
	right: 30px;
	bottom: 35px;
	z-index: 1;
	transition: .3s linear;
}

.featured-game-icon {
	margin-bottom: 20px;
}

.featured-game-content h4 {
	font-size: 22px;
	line-height: 1;
	margin-bottom: 5px;
	text-transform: uppercase;
}

.featured-game-content h4 span,
.featured-game-meta i {
	color: #e9a301;
}

.featured-game-content h4 a:hover {
	color: #fff;
}

.featured-game-meta {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 500;
	color: #e3e3e3;
}

.featured-game-meta i {
	margin-right: 5px;
}

.featured-game-overlay-content {
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	bottom: auto;
	opacity: 0;
}

.featured-game-overlay-content .featured-game-meta {
	justify-content: center;
	color: #fff;
}

.featured-game-overlay-content h4,
.featured-game-overlay-content h4 a:hover {
	color: #1c1121;
}

.featured-game-overlay-content h4 span,
.featured-game-overlay-content .featured-game-meta i {
	color: #fff;
}

.featured-game-item:hover .featured-game-content {
	opacity: 0;
	visibility: hidden;
}

.featured-game-item:hover .featured-game-overlay-content,
.featured-game-item:hover::before {
	opacity: 1;
	visibility: visible;
}

.featured-game-bg {
	background-position: center;
	background-size: cover;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.featured-game-icon img {
	display: inline-block;
}

.featured-game-area .container-full {
	padding-left: 60px;
	padding-right: 60px;
}

.new-released-game-area .no-gutters [class*="col-"] {
	padding-left: 0;
	padding-right: 0;
}

.new-released-game-area {
	background: #f7f7f7;
}

/* 12. Cta */
.cta-bg {
	/* background-image: url(../img/bg/cta_bg.jpg); */
	padding: 120px 0;
	background-position: center;
	background-size: cover;
	box-shadow: 0px 5px 10px 0px rgba(11, 11, 11, 0.52);
}

.cta-content {
	padding: 0 50px;
}

.cta-content .section-title h2 {
	font-size: 50px;
	margin-bottom: 5px;
}


/* 13. Tournament */
.tournament-top-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	background-color: rgb(32, 19, 43);
	box-shadow: 0px 4px 5.58px 0.42px rgba(0, 0, 0, 0.13), inset 0px 1px 16px 0px rgba(228, 161, 1, 0.17);
	padding: 25px 30px;
}

.tournament-title h2 {
	font-size: 26px;
	letter-spacing: -1px;
}

.tournament-menu {
	display: flex;
	flex-wrap: wrap;
}

.tournament-menu button {
	font-size: 14px;
	border: none;
	background: transparent;
	padding: 0;
	color: #a0a0a0;
	font-weight: 600;
	cursor: pointer;
	position: relative;
	margin-left: 30px;
	transition: .4s linear;
}

.tournament-menu button:first-child {
	margin-left: 0;
}

.tournament-menu button::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: -6px;
	width: 100%;
	height: 3px;
	opacity: 0;
	transition: .4s linear;
}

.tournament-menu button.active::before {
	opacity: 1;
}

.tournament-area {
	background-color: rgb(27, 16, 36);
	box-shadow: 0px 5px 12.09px 0.91px rgba(0, 0, 0, 0.34);
}

.tournament-schedule-wrap {
	padding: 30px 30px 15px;
}

.tournament-schedule-item {
	display: flex;
	align-items: center;
	background: #23162e;
	padding: 37px 20px;
	justify-content: space-between;
	height: 100%;
}

.tournament-schedule-content {
	padding: 35px 35px;
	position: relative;
	background: #20132b;
	height: 100%;
}

.tournament-schedule-content h3 a:hover span {
	color: #fff;
}

.tournament-schedule-content p {
	margin-bottom: 0;
}

.tournament-schedule-meta {
	display: flex;
	align-items: center;
	position: absolute;
	right: 0;
	bottom: 0;
}

.tournament-schedule-meta h5 {
	font-size: 13px;
	font-weight: 700;
	margin-bottom: 0;
	color: #c9c9c9;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
}

.tournament-schedule-meta h5 span {
	margin-left: 5px;
}

.tournament-schedule-meta a {
	display: block;
	border: 1px dashed #383838;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 800;
	padding: 12px 13px;
	line-height: 1;
	margin-left: 18px;
}

.tournament-schedule-meta a:hover {
	border: 1px solid #e4a101;
	color: #1c1121;
}

.latest-match-bg {
	/* background-image: url(../img/bg/latest_match_bg.jpg); */
	background-position: center;
	background-size: cover;
}

.latest-match-box {
	display: flex;
	align-items: center;
	background: #211e23;
	padding: 15px;
	box-shadow: 0px 5px 12.09px 0.91px rgba(0, 0, 0, 0.34);
}

.latest-match-box .tournament-schedule-content {
	padding: 41px 30px;
	background: #1c181e;
}

.latest-match-box .tournament-schedule-content p {
	font-size: 13px;
	line-height: 2;
	padding-right: 20px;
}

.latest-match-box .tournament-schedule-meta a {
	color: #d7d5d5;
	background: #222225;
}

.latest-match-box .tournament-schedule-meta a:hover {
	color: #1c1121;
}

.team-member-thumb {
	position: relative;
	margin-bottom: 25px;
	overflow: hidden;
}

.team-member-thumb img {
	width: 100%;
	transition: .3s linear;
}

.team-member-social {
	position: absolute;
	right: 20px;
	top: 30px;
	transform: translateX(30px);
	opacity: 0;
	transition: .3s linear;
}

.team-member-thumb::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #e9a301;
	transition: .3s linear;
	opacity: 0;
}

.team-member-content h4 {
	font-size: 20px;
	color: #1c1121;
	text-transform: uppercase;
	line-height: 1;
	margin-bottom: 5px;
}

.team-member-box:hover .team-member-content h4 a {
	color: #e8a301;
}

.team-member-box:hover .team-member-thumb img {
	box-shadow: 0px 2px 21px 0px rgba(17, 8, 23, 0.16);
}

.team-member-box:hover .team-member-thumb::before {
	opacity: .9;
}

.team-member-box:hover .team-member-social {
	transform: translateX(0px);
	opacity: 1;
}

.team-member-content span {
	display: block;
	font-size: 14px;
	font-weight: 500;
	color: #6c6c6c;
}

.team-member-social ul li {
	display: block;
	margin-bottom: 13px;
}

.team-member-social ul li a {
	color: #fff;
	font-size: 15px;
}

.team-bg {
	/* background-image: url(../img/bg/third_team_bg.jpg); */
	background-position: bottom center;
	background-size: cover;
	padding: 110px 0 90px;
}

.third-team-content {
	position: relative;
	padding: 20px 50px;
	z-index: 1;
}

.third-team-content .main-bg {
	/* background-image: url(../img/bg/team_content_bg.png); */
	width: 302px;
	height: 93px;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	z-index: -1;
	top: -10px;
}

.third-team-content .hover-bg {
	/* background-image: url(../img/bg/team_content_hover_bg.png); */
	width: 302px;
	height: 93px;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	z-index: -1;
	top: -10px;
	opacity: 0;
	transition: .3s linear;
}

.third-team-content h5 {
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 700;
	font-family: 'Poppins', sans-serif;
	line-height: 1;
	margin-bottom: 5px;
}

.third-team-content span {
	display: block;
	text-transform: uppercase;
	font-size: 11px;
	font-weight: 700;
	letter-spacing: 2px;
	color: #edcb74;
	transition: .3s linear;
}

.third-team-content h5 a:hover {
	color: #fff;
}

.third-team-item:hover .third-team-content .hover-bg {
	opacity: 1;
}

.third-team-item:hover .third-team-content span {
	color: #1f2026;
}

/* Game-gallery */
.game-gallery-area.position-relative {
	z-index: 1;
	padding: 120px 0 0;
	margin-bottom: -150px;
}

.game-gallery-bg {
	/* background-image: url(../img/bg/game_gallery_bg.png); */
	background-position: top center;
	background-size: cover;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 715px;
	z-index: -1;
}

.game-gallery-item img {
	max-width: 100%;
	display: inline-block;
}

.game-gallery-item {
	text-align: center;
	max-width: 1080px;
	margin: 0 auto;
}

.game-gallery-active .slick-active.slick-center {
	margin-top: 0;
}

.game-gallery-active .slick-slide {
	margin-top: 80px;
	transition: .4s linear;
}

.game-gallery-area .slider-nav {
	max-width: 1080px;
	position: absolute;
	bottom: 100px;
	left: 0;
	right: 0;
	margin: 0 auto;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.slider-nav>span {
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 600;
	color: #bebebe;
	cursor: pointer;
	transition: .3s linear;
}

.slider-nav>span.slick-next i {
	margin-left: 5px;
}

.slider-nav>span.slick-prev i {
	margin-right: 5px;
}

.slider-nav>span:hover {
	color: #edcb74;
}

.just-gamers-circle-shape {
	position: absolute;
	bottom: 215px;
	left: 60%;
	z-index: -1;
}

.character-Taylor {
	bottom: 231px;
}

.just-gamers-circle-shape img:not(.rotateme) {
	width: 60px;
}

.just-gamers-circle-shape img:nth-child(2) {
	position: absolute;
	top: 6px;
	right: -28px;
}

.gamers-chart-shape {
	position: absolute;
	right: 0;
	top: 70px;
}

/* 15. Roadmap */

.roadmap-item {
	padding: 20px;
	margin-bottom: 40px;
	min-height: 320px;
	z-index: 1;
	position: relative;
	background-size: 100% 100%;
	background-position: center;
    border: 6px solid transparent;
    background: linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%;
    background-repeat: no-repeat;
    background-size: 10rem 3rem;
}

.roadmap-item .tag {
	display: inline-block;
	position: relative;
	z-index: 1;
	font-size: 20px;
	border: 1px solid #edcb74;
	box-shadow: 0 0 10px #edcb74;
	border-radius: 5px;
	padding: 5px 20px;
	margin-bottom: 10px;
	font-weight: 600;
	text-transform: uppercase;
}

.roadmap-item ul li {
	z-index: 1;
	padding-bottom: 5px;
	border-bottom: 1px solid #ececec52;
	margin-bottom: 10px;
	font-size: 16px;
}

.roadmap-item ul li:last-child {
	border-bottom: 0;
}


.nft-wrap,
.items-wrap {
	background-attachment: fixed;
}

/* .nft-wrap:before,
.items-wrap:before {
    content: "";
    position: absolute;
    inset: 0;
    display: block;
    background-color: #0b0b0b99;
    transition: all .5s ease-in-out;
} */

.nft-circle-shape {
	z-index: 1;
	left: unset;
	right: -15%;
	top: 0;
}

.nft-circle-shape img {
	width: 600px;
}

.developers {
	display: flex;
	align-items: stretch;
	justify-content: center;
	gap: 30px;
	margin: 0 auto;
}

.developers img {
	width: 100%;
}

.developer-item {
	flex: 0 0 150px;    
	border: 1px solid;
    min-height: 88px;
    display: flex;
    align-items: center;
    padding: 10px;
    background-position: center;
    border: 1px solid transparent;
    background: linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
}

.btns {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btns a img {
    margin-bottom: 0px;
}

.btns a {
    padding: 10px;
    background-position: 50%;
    border: 6px solid transparent;
}

.btns a:hover{
    background: linear-gradient(90deg,#f0eb8d 0.2rem,transparent 0) 0 0,linear-gradient(90deg,#f0eb8d 0.2rem,transparent 0) 0 100%,linear-gradient(270deg,#f0eb8d 0.2rem,transparent 0) 100% 0,linear-gradient(270deg,#f0eb8d 0.2rem,transparent 0) 100% 100%,linear-gradient(180deg,#f0eb8d 0.2rem,transparent 0) 0 0,linear-gradient(180deg,#f0eb8d 0.2rem,transparent 0) 100% 0,linear-gradient(0deg,#f0eb8d 0.2rem,transparent 0) 0 100%,linear-gradient(0deg,#f0eb8d 0.2rem,transparent 0) 100% 100%;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
}

.list-social {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 30px;
}

.list-social a svg {
	width: 32px;
	height: 32px;
	color: #edcb74;
	transition: .4s ease;
}

.list-social a:hover svg {
	color: #edcb74;
	transform: scale(1.2);
}

.mobile-button-menu,
.mobile-menu {
	display: none;
}



/* 26. Footer */
.footer-bg {
	background-color: #f9f9f9;
	background-position: top center;
	background-size: cover;
	padding-bottom: 65px;
	position: relative;
	z-index: 1;
}

.third-footer-bg {
	padding-top: 90px;
	padding-bottom: 40px;
}

.footer-text p {
	font-size: 15px;
	color: #90949c;
	margin-bottom: 15px;
}

.footer-contact ul li {
	margin-bottom: 10px;
	font-size: 15px;
	color: #90949c;
	line-height: 28px;
}

.footer-contact ul li i {
	margin-right: 10px;
}

.footer-contact ul li span {
	font-weight: 500;
}

.footer-contact ul li:last-child {
	margin-bottom: 0;
}

.fw-title h5 {
	font-size: 18px;
	color: #c8c8c8;
	margin-bottom: 0;
	line-height: 1;
	padding-bottom: 20px;
	position: relative;
}

.fw-title h5::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 30px;
	height: 3px;
}

.fw-link ul li {
	display: block;
}

.fw-link ul li a {
	color: #90949c;
	font-size: 14px;
	line-height: 40px;
	display: inline-block;
}

.fw-link ul li a:hover {
	padding-left: 5px;
}

.footer-social ul {
	display: flex;
	flex-wrap: wrap;
}

.footer-social ul li {
	display: block;
	margin-right: 9px;
}

.footer-social ul li a {
	width: 36px;
	height: 36px;
	background-color: rgb(59, 89, 152);
	display: block;
	text-align: center;
	line-height: 36px;
	color: #fff;
	font-size: 14px;
	border-radius: 2px;
}

.footer-social ul li:nth-child(2) a {
	background: #55acee;
}

.footer-social ul li:nth-child(3) a {
	background: #d71e18;
}

.footer-social ul li:nth-child(4) a {
	background: #1565c0;
}

.footer-newsletter input {
	width: 100%;
	background: #24182d;
	border: none;
	padding: 12px 20px;
	color: #fff;
	padding-right: 60px;
	font-size: 14px;
}

.footer-newsletter input::placeholder {
	color: #7c7c7d;
	font-size: 14px;
}

.footer-newsletter form {
	position: relative;
}

.footer-newsletter button {
	position: absolute;
	width: 53px;
	height: 100%;
	border: none;
	color: #1c1121;
	right: 0;
	top: 0;
	cursor: pointer;
	font-size: 14px;
}

.footer-fire {
	position: absolute;
	left: 90px;
	bottom: 0;
	z-index: -1;
}

.footer-fire-right {
	left: auto;
	right: 90px;
}

.copyright-wrap {
	border-top: 1px solid #ececec14;
	background: #050505;
	padding: 20px 0;
}

.copyright-text p {
	font-size: 14px;
	color: #fff;
	margin-bottom: 0;
}

.copyright-text p a {
	font-weight: 500;
}

.btn-play-demo {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	border-radius: 5px;
	border: 2px solid transparent;
	background:
		linear-gradient(#000 0 0) padding-box,
		linear-gradient(to right, #edcb74, #F9CA1A) border-box;
	border-radius: 99px;
	color: #fff;
	font-size: 24px
}

.btn-play-demo img {
	width: 30px;
	margin-bottom: 0;
}

.btn-play-demo:hover {
	box-shadow: inset 0 0 0 3em #fff;
	color: #edcb74;
}

/* 27. Preloader */
#preloader {
	background-color: #161417;
	height: 100%;
	width: 100%;
	position: fixed;
	z-index: 1;
	margin-top: 0px;
	top: 0px;
	z-index: 99;
}

#loading-center {
	width: 100%;
	height: 100%;
	position: relative;
}

#loading-center-absolute {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.preloader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 999999;
	background: #ffffff;
}

.preloader .icon {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0);
	background-position: center center;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}

.page-loaded .preloader .icon {
	opacity: 0;
}

.banner-bg {
	background-size: cover;
	min-height: 70vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.banner-title {
	font-size: 48px;
}

.main-banner .info {
	padding: 20px;
	text-align: center;
	background-color: #000;
	border-radius: 8px;
}

.main-banner .info-text {
	font-size: 16px;
}

.main-banner .info-numbers {
	font-size: 24px;
	color: #edcb74;
	font-weight: 600;
}

.box-desc span {
	margin-bottom: 10px;
	display: block;
}

.box-desc {
	text-align: center;
	margin-top: 30px;
}

.character-tab-wrap {
	display: grid;
	flex-wrap: wrap;
	align-items: center;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: 20px;
	margin-bottom: 40px;
	text-align: center;
}

.character-tab-wrap .character-item {
	border: 10px solid;
	border-image-slice: 1 !important;
	border-width: 3px;
	border-image-source: linear-gradient(to bottom, #ec892b, #ffffff);
}

.character-tab-wrap .character-item img {
	width: 100%;
}


.character-tab-wrap .character-item h3 {
	font-size: 20px;
	font-weight: 400;
}

ul.tabs-vr-head {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 30px;
}

ul.tabs-vr-head li {
	border-radius: 0px !important;
	min-width: 200px;
	text-align: center;
	min-height: 50px;
	padding: 5px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.5s;
	background-color: transparent;
	border: 1px solid #edcb74;
	box-shadow: 0 0 10px #edcb74;
	overflow: hidden;
	font-size: 20px;
	font-family: 'Orbitron', cursive;
	letter-spacing: .1rem !important;
}

ul.tabs-vr-head li:hover {
	box-shadow: inset 0 0 0 2em #edcb74;
	color: #fff;
}

ul.tabs-vr-head li.react-tabs__tab--selected {
	box-shadow: inset 0 0 0 2em #edcb74;
	color: #fff;
	border-color: #edcb74;
}

ul.world-tabs li.react-tabs__tab--selected {
	box-shadow: inset 0 0 0 4em #edcb74;
}

.react-tabs__tab:focus {
	box-shadow: unset;
	border-color: unset;
	outline: none;
}

.react-tabs__tab:focus:after {
	display: none;
}

.tabs-vr {
	margin-top: 30px;
	position: relative;
	z-index: 10;
}

.tab-text {
	width: 80%;
	text-align: center;
	margin: 0 auto;
	font-size: 16px;
	color: #fff;
}

.title {
	text-transform: uppercase;
}

.desc {
	width: 75%;
	margin: 0 auto;
	text-align: center;
	display: block;
	color: #fff;
}

.characters-home-wrapper,
.benefits-wrapper {
	background: linear-gradient(0deg, rgba(242, 137, 32, 1) 0%, rgb(11 11 11) 60%, rgb(11 11 11) 100%);
	position: relative;
	overflow: hidden;
}

.characters-overlay {
	position: absolute;
	inset: 0;
	opacity: 1;
	z-index: -1;
	background-color: #00000087;
}

.tabs-vr-body {
	margin-top: 40px;
}

.whatismetazombie-banner {
	width: 50%;
	margin: 0 auto;
	display: block;
	margin-top: 40px;
	margin-bottom: 40px;
}

.whatismetazombie-box {
	text-align: center;
	padding: 20px 10px;
	border: 8px solid;
	border-image-slice: 1 !important;
	border-width: 3px;
	border-image-source: linear-gradient(to left, #ec892b, #ffffff);
	overflow: hidden;
	height: 100%;
}

.whatismetazombie-box span {
	text-align: justify;
	color: #fff;
}

.whatismetazombie-box h3 {
	text-align: center;
	font-size: 24px;
}

.benefit-box {
	display: grid;
	overflow: hidden;
	grid-template-columns: repeat(1, 1fr);
	grid-auto-rows: 1fr;
	gap: 10px;
	align-items: center;
	justify-content: center;
	padding: 20px 50px;
	text-align: center;
	min-height: 100%;
	border: 10px solid;
	border-image-slice: 1 !important;
	border-width: 3px;
	border-image-source: linear-gradient(to bottom, #ec892b, #ffffff);
	overflow: hidden;
}

.benefit-box-icon {
	margin: 0 auto;
	width: 64px;
	height: 64px;
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ec892c;
	border-radius: 50%;
}

.benefit-box span,
.benefit-box svg {
	color: #fff;
}

/* .benefit-box span{
    font-size: 14px;
} */

.gallery-item {
	overflow: hidden;
	position: relative;
	border: 1px solid #edcb74;
	overflow: hidden;
}

.gallery-item img {
	transition: all 0.5s ease-in-out !important;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
}

.gallery-item-hover:before {
	content: "";
	opacity: 0;
	z-index: 9;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: inline-block;
	transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	background: #0B101766;
	background: -webkit-linear-gradient(top, #0B101766, #0B1017CC);
	background: -moz-linear-gradient(top, #0B101766, #0B1017CC);
	background: linear-gradient(to bottom, #0B101766, #0B1017CC);
}

.gallery-item-hover:hover img {
	transform: scale(1.3);
}

.gallery-item-hover:hover:before {
	opacity: 1;
}

.box-btn {
	align-items: center;
	justify-content: center;
	gap: 20px;
}

.box-btn .btn {
	min-width: 200px;
}

.box-connect-wrap,
.box-history-wrap {
	padding: 40px;
	background: rgb(19 19 19 / 85%);
	backdrop-filter: blur(32px);
	border: 1px solid hsl(0deg 0% 90% / 14%);
	border-radius: 10px;
}

.btn-connect-metaMask img {
	width: 24px;
	height: 24px;
}

.btn-connect-metaMask {
	gap: 15px;
	color: #fff;
}

.btn-connect-metaMask:hover {
	box-shadow: inset 0 0 0 4em #fff;
}

.btn-outline {
	background-color: transparent;
	border: 1px solid #edcb74;
	color: #fff;
	padding: 15px 30px;
	transition: 0.5s;
}

.btn-outline:hover,
.btn-outline:focus {
	box-shadow: inset 0 0 0 2em #edcb74;
	color: #fff;
}

.list-community {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 30px;
}

.list-community .btn {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	border: 1px solid #edcb74;
	box-shadow: 0 0 10px #edcb74;
	color: #edcb74;
	transition: .4s all;
}

.list-community .btn:hover {
	transform: translateY(-10px);
}

.copyright-left {
	display: flex;
	align-items: center;
	gap: 10px;
}

.list-link-footer {
	display: flex;
	align-items: center;
}

.list-link-footer li {
	padding: 0 5px;
	border-left: 1px solid #edcb74;
}

.list-link-footer li a {
	color: #edcb74;
	font-weight: 600;
}

.community-wrap {
	min-height: 400px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	display: flex;
	align-items: center;
}

.community-wrap span {
	color: #fff;
}

/* About us */

.vision-title {
	text-align: center;
	margin-bottom: 30px;
}

.vision-card-wrap {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: 20px;
	margin-bottom: 50px;
}

.vision-card {
	text-align: center;
	background: #000;
	padding: 20px 25px;
	border: 10px solid;
	border-image-slice: 1 !important;
	border: 1px solid #edcb74;
	box-shadow: 0 0 10px #edcb74;
	overflow: hidden;
}

.vision-card .icon svg {
	width: 72px;
	height: 72px;
	color: #fff;
}

.vision-card .icon {
	background: #edcb74;
	width: 100px;
	height: 100px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin-bottom: 20px;
}

.vision-card h3 {
	font-size: 20px;
	min-height: 50px;
	font-family: 'Montserrat', sans-serif;
	letter-spacing: inherit;
}

.vision-card span {
	color: #fff;
}

.vision-text span {
	display: block;
	margin-bottom: 10px;
	color: #fff;
}

.about-team-item-three {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: 20px;
	margin-bottom: 20px;
}

.about-team-item img {
	width: 144px;
	height: 144px;
	object-fit: cover;
	border-radius: 50%;
	margin-bottom: 20px;
}

.about-team-item {
	background: rgb(28 28 28);
	text-align: center;
	border-radius: 10px;
	padding: 20px;
	position: relative;
	overflow: hidden;
}

.about-team-item h4 {
	margin-bottom: 0px;
}

.about-team-item span {
	color: #fff;
}

.about-team-item-four {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	gap: 20px;
}

.about-team-item .social-overlay {
	background-image: linear-gradient(to left, #ec892b73, #ffffff3b);
	position: absolute;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: .5s;
}

.about-team-item:hover .social-overlay {
	opacity: 1;
}

.about-team-item .social-overlay a {
	width: 64px;
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ec892b;
	border-radius: 50%;
	transition: .5s ease-in;
}

.about-team-item .social-overlay svg {
	width: 32px;
	height: 32px;
	color: #fff;
}

.about-team-item .social-overlay a:hover {
	transform: scale(1.2);
}

.vr-hear-icon {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.vr-hear-icon svg {
	width: 24px;
	height: 24px;
	color: #fff;
}

.vr-hear-icon span {
	font-size: 16px;
	color: #fff;
	font-family: 'Montserrat', cursive;
}

.world-tab-content {
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	justify-content: space-between;
}

.world-tab-left {
	flex: 0 0 45%;
	min-height: 300px;
}

.world-tab-right {
	flex: 0 0 45%;
}

.world-tab-right img {
	width: 100%;
}

.world-tab-center {
	flex: 1;
}

.world-tabs {
	position: absolute;
	left: 0;
	top: 200px;
	flex-wrap: wrap;
	width: 45%;
	display: grid !important;
	grid-template-columns: repeat(4, minmax(0, 1fr));
}

.world-tabs li {
	min-width: 100px !important;
}

.vision-text .roadmap-head {
	padding: 10px;
	background: #000;
	margin-bottom: 30px;
	position: relative;
	border: 10px solid;
	border-image-slice: 1 !important;
	border: 1px solid #edcb74;
	box-shadow: 0 0 10px #edcb74;
	overflow: hidden;
}

.vision-text .roadmap-head ul li {
	list-style: disc;
	margin-left: 30px;
	margin-bottom: 5px;
	color: #fff;
}

.vision-text .roadmap-head .tag {
	background: #edcb74;
	color: #fff;
	display: inline-block;
	padding: 5px 10px;
	margin-bottom: 10px;
}

.video-react .video-react-big-play-button.video-react-big-play-button-center {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	border: 2px solid #edcb74;
}

.video-react .video-react-big-play-button:before {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #edcb74;
}

.parent-list li {
	list-style: auto;
}

.children-list li {
	list-style: lower-alpha;
}

.children-list li ol li {
	list-style: outside;
}

.characters-circle-shape {
	width: 100%;
	height: 100%;
	z-index: -1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.characters-circle-shape h1 {
	font-family: 'Orbitron', cursive;
	letter-spacing: .1rem !important;
	text-transform: uppercase;
	font-size: 20rem;
	position: absolute;
	left: 50%;
	top: 0;
	transform: translateX(-50%);
	opacity: .1;
	background: -webkit-linear-gradient(#c7c7c773, #0b0b0b);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.whatismetazombie-wrapper {
	position: relative;
}

.whatismetazombie-circle-shape,
.about-circle-shape {
	width: 100%;
	height: 100%;
	z-index: -1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.whatismetazombie-circle-shape h1 {
	font-family: 'Orbitron', cursive;
	letter-spacing: .1rem !important;
	text-transform: uppercase;
	z-index: 0;
	font-size: 10rem;
	position: absolute;
	left: 50%;
	top: 20%;
	transform: translate(-50%, -50%);
	opacity: .1;
	text-align: center;
	/* color: #ffffff4f; */
	background: -webkit-linear-gradient(#c7c7c773, #0b0b0b);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.about-us-page {
	position: relative;
	overflow: hidden;
}

.about-us-page .about-circle-shape h1 {
	width: 100%;
	font-family: 'Orbitron', cursive;
	letter-spacing: .1rem !important;
	text-transform: uppercase;
	z-index: 0;
	font-size: 10rem;
	position: absolute;
	left: 50%;
	top: 10%;
	transform: translate(-50%, -50%);
	opacity: .1;
	text-align: center;
	background: -webkit-linear-gradient(#c7c7c773, #0b0b0b);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.blockchain-tab-img {
	text-align: center;
}

.blockchain-tab-img img {
	width: 65%;
	margin: 0 auto;
	animation-duration: 2.5s;
	animation-direction: alternate-reverse;
	animation: swing ease-in-out 2s infinite alternate;
}

.world-tab-center-text ul {
	margin-left: 2rem;
}

.world-tab-center-text ul li {
	list-style: disc;
	margin-bottom: 10px;
}

/* Comic */

.comic-wrap {
	position: relative;
	z-index: 10;
}

.comic-wrap .comic-item img {
	width: 100%;
	cursor: pointer;
	object-fit: cover;
}

.comic-home-wrapper,
.page-comic-content {
	position: relative;
	overflow: hidden;
}

.page-comic-numbers {
	position: absolute;
	z-index: 999;
	color: #fff;
	bottom: 0;
	right: 0;
	width: 40px;
	height: 40px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.page-comic-text img {
	width: 100%;
	height: 400px;
}

.page-comic-navigation {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.page-comic-navigation .page-comic-button {
	width: 50%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: .4s ease-in;
	cursor: pointer;
	background-color: #e3d0b5;
	color: #fff;
}

.page-comic-navigation .page-comic-button:hover {
	background-color: #998466;
}

.page-comic-navigation .page-comic-prev {
	box-shadow: inset -7px 0 30px -7px rgb(0 0 0 / 40%);
}

.page-comic-navigation .page-comic-next {
	box-shadow: inset 7px 0 30px -7px rgb(0 0 0 / 40%);
}

.page {
	padding: 10px;
	background-color: #e3d0b5;
	color: #785e3a;
	/* border: 1px solid #c2b5a3; */
	overflow: hidden;
}

.page .page-comic-content {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
}

.page.--right {
	border-left: 0;
	box-shadow: inset 7px 0 30px -7px rgb(0 0 0 / 40%);
}

.page.--left {
	box-shadow: inset -7px 0 30px -7px rgb(0 0 0 / 40%);
}

.page.page-cover {
	background-color: #e3d0b5;
	color: #785e3a;
}

.page.page-cover .page-content {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.page.page-cover.page-cover-top {
	box-shadow: inset 0 0 30px 0 rgb(36 10 3 / 50%), -2px 0 5px 2px rgb(0 0 0 / 40%);
}

.page-cover img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.demo-book {
	background-color: #e3d0b5;
	border-left: 0;
	box-shadow: inset 7px 0 30px -7px rgb(0 0 0 / 40%);
}

@keyframes swing {
	0% {
		transform: rotate3d(1, -1, 1, 10deg);
	}

	100% {
		transform: rotate3d(1, 1, 1, -8deg);
	}
}

.mint-page {
	min-height: 85vh;
}

.box-connect-head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid hsl(0deg 0% 90% / 14%);
	padding-bottom: 20px;
}

.box-coin img {
	width: 20px;
	animation-duration: 2.5s;
	animation-direction: alternate-reverse;
	animation: swing ease-in-out 2s infinite alternate;
}

.box-connect-head-left span {
	display: block;
	margin-bottom: 5px;
	text-align: left;
}

.box-connect-head-right>span {
	display: block;
	margin-bottom: 5px;
}

.box-coin {
	display: flex;
	align-items: center;
	gap: 10px;
}

.box-connect-body {
	padding-top: 30px;
}

.mint-page span {
	font-family: 'Orbitron', cursive;
	letter-spacing: .1rem !important;
}

.mint-page .mint-wrap{
	position: relative;
}

.btn-mint {
	padding: 15px 20px;
	min-width: 120px;
	position: relative;
	animation: pulse 1s infinite 1s cubic-bezier(0.25, 0, 0, 1);
	box-shadow: 0 0 0 0 #edcb74;
	transition: .5s all ease-in-out;
}

.btn-connect-metaMask {
	animation: pulse 2s infinite 1.5s cubic-bezier(0.25, 0, 0, 1);
	box-shadow: 0 0 0 0 #edcb74;
	transition: .5s all ease-in-out;
}

@keyframes pulse {
	to {
		box-shadow: 0 0 0 14px rgba(255, 255, 255, 0);
	}
}

.btn-mint:hover,
.btn-connect-metaMask:hover {
	animation: unset;
}

.list-nft {
	margin-top: 30px;
	display: grid;
	grid-template-columns: repeat(5, minmax(0, 1fr));
	gap: 20px;
}


.nft-item {
	background: rgb(19 19 19 / 85%);
	-webkit-backdrop-filter: blur(32px);
	backdrop-filter: blur(32px);
	overflow: hidden;
	border: 1px solid #edcb74;
	box-shadow: 0 0 10px #edcb74;
	border-radius: 5px;
	overflow: hidden;
}


.nft-item img {
	width: 100%;
}

.table thead th {
	border-bottom: 1px solid #2f2f2f;
}

.table td,
.table th {
	border-top: 1px solid #2f2f2f;
}

.title-mint {
	font-size: 32px;
	font-weight: 600;
	display: block;
	margin-bottom: 20px;
}

.get-nft img {
	width: 100%;
	height: 250px;
	object-fit: cover;
	border-radius: 5px;
}

.mint-list-nft {
	width: 300px;
	padding: 10px;
	border: 1px solid hsl(0deg 0% 90% / 14%);
}

.mint-nft-item img {
	width: 100%;
}

.disclaimer-us-page {
	position: relative;
	overflow: hidden;
	min-height: 95vh;
}

.text-follow {
	background: #000000b3;
	padding: 15px 10px;
	margin-top: 10px;
	border: 8px solid;
	border-image-slice: 1 !important;
	border-width: 3px;
	border-image-source: linear-gradient(to left, #ec892b, #ffffff);
	overflow: hidden;
	color: #fff;
}

.benefit-box-wrap {
	margin-bottom: 30px;
}

.filter-wrapper {
	width: 100%;
	display: grid;
	flex-wrap: wrap;
	align-items: center;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	gap: 20px;
}

.filter-select-option {
	display: flex;
	flex-direction: column;
}

.filter-select-option label.filter-label {
	color: #fff;
}

.filter-select-option select {
	padding: 5px;
	box-shadow: none;
	border-radius: 5px;
	border: 0px;
	background-color: #4e4e4e6b;
	color: #fff;
}

.btn-right {
	text-align: right;
}

.discover-filter-button {
	padding: 10px 25px;
	display: inline-flex;
	align-items: center;
	gap: 5px;
}

.reset-filter-button {
	padding: 10px 20px;
	margin-top: 20px;
	border-radius: 5px !important;
	display: flex;
	align-items: center;
	gap: 5px;
}

.market-list {
	width: 100%;
	display: grid;
	flex-wrap: wrap;
	align-items: center;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	gap: 30px;
}

.mynft-list{
	width: 100%;
	display: grid;
	flex-wrap: wrap;
	align-items: center;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: 30px;
}

.nft-style-item {
	position: relative;
	padding: 10px 15px;
	border-radius: 10px;
	background: #242435;
	height: 100%;
	z-index: 5;
}

.nft-style-item:after {
	content: "";
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: rgb(19 19 19);
	border: 1px solid #edcb74;
	position: absolute;
	border-radius: 10px;
	z-index: -1;
}

.nft-common:after{
	border-color: #78abcc;
    box-shadow: 0 0 10px #78abcc;
}

.nft-uncommon:after{
	border-color: #278cce;
    box-shadow: 0 0 10px #278cce;
}

.nft-rare:after{
	border-color: #BE2E8F;
    box-shadow: 0 0 10px #BE2E8F;
}

.nft-epic:after{
	border-color: #ED322E;
    box-shadow: 0 0 10px #ED322E;
}

.nft-style-item::before {
	content: "";
	position: absolute;
	top: auto;
	bottom: 0;
	left: 0;
	border-radius: 15px;
	background-image: linear-gradient(100deg, #13131d, #ec892b);
	background-repeat: repeat-x;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	width: 100%;
	height: 100%;
	z-index: -2;
}

.nft-style-item:hover::before {
	transform: rotate(2deg) translateX(-7px) translateY(11px);
}

.nft-style-item .card-thumbnail {
	overflow: hidden;
	border-radius: 5px;
}

.nft-style-item img {
	border-radius: 5px;
	object-fit: cover;
	width: 100%;
	height: auto;
	transition: 0.5s;
}

.nft-style-item:hover img {
	transform: scale(1.1);
}

.nft-style-item .bid-react-area {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}

.card-content {
	margin-top: 8px;
	text-align: center;
}

.nft-style-item .bid-react-area .last-bid img {
    width: 24px;
}

.nft-style-item .bid-react-area .last-bid {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #fff;
}

.nft-item-info {
    display: flex;
    flex-wrap: wrap;
	justify-content: center;
    gap: 5px;
    color: #fff;
    font-size: 10px;
}

.nft-item-info span {
    border-right: 1px solid #fff;
	padding-right: 5px;
}

.nft-item-info span:last-child {
    border-right: 0px;
}

.nft-item-info span strong{
	font-weight: 500;
	color: #edcb74;
}

.nft-style-item .bid-react-area {
	border-top: 1px solid #ececec3b;
	padding-top: 10px;
	justify-content: center;
	margin-bottom: 8px;
}

.nft-style-item .bid-react-area .last-bid {
	font-size: 16px;
}

.nft-style-item a {
	display: block;
	color: #edcb74;
	font-size: 18px;
}

/* Breadcrumb */

.breadcrumb-list {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.breadcrumb-list li a {
	color: #fff;
	transition: 0.4s;
}

.breadcrumb-list li.separator {
	margin: 0 15px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.breadcrumb-list li.current {
	color: #fff;
	text-transform: inherit;
}

.explore-style-one {
	background: #000;
	border: 1px solid #edcb74;
    box-shadow: 0 0 10px #edcb7496;
	padding: 15px;
	border-radius: 10px;
	transition: 0.3s;
	transition: 0.3s;
}

.explore-style-one .thumb {
	position: relative;
	overflow: hidden;
	display: block;
	border-radius: 10px;
}

.explore-style-one .thumb img {
	display: block;
	width: 100%;
	height: auto;
	object-fit: cover;
	transition: 0.5s;
}

.nft-details .explore-style-one .thumb img {
	transform: scale(1);
}

.info-wrapper {
	background: #000;
	padding: 6px 15px;
	border-radius: 5px;
	border: 1px solid #edcb74;
    box-shadow: 0 0 10px #edcb7496;
	min-width: 248px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
}

.info-wrapper .price {
	font-size: 24px;
}

.bidder {
	font-size: 24px;
	margin-bottom: 10px;
}

.tabs-nft-detail {
	margin-top: 20px;
}

.tabs-nft-head {
	background: #000;
	border: 1px solid #edcb74;
    box-shadow: 0 0 10px #edcb7496;
	color: rgba(255, 255, 255, 0.75);
	padding: 10px 20px;
	font-size: 16px;
	font-weight: 500;
	border-radius: 6px;
	display: inline-flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.tabs-nft-head li {
	flex: 1;
	text-align: center;
	background: transparent;
	border: 1px dashed #fff;
	position: relative;
	margin: 6px;
	border-radius: 6px;
	border: 1px dashed rgb(255 255 255 / 8%);
	transition: 0.3s;
}

.tabs-nft-head li.react-tabs__tab--selected {
	background: #edcb74;
	color: #000;
	border-radius: 5px;
	border: transparent;
}

.tabs-nft-body {
	background: #000;
	border: 1px solid #edcb74;
    box-shadow: 0 0 10px #edcb7496;
	padding: 23px;
	border-radius: 6px;
	margin-top: 20px;
	display: none;
}

.tabs-nft-body.react-tabs__tab-panel--selected {
	display: block;
}

.show {
	display: block;
}

.modal {
	background-color: rgba(12, 12, 12, .47843137254901963);
	transition: all .5s ease-in-out;
	-webkit-backdrop-filter: blur(32px);
	backdrop-filter: blur(32px);
}

.modal-dialog {
	max-width: 450px;
	min-height: calc(100% - 3.5rem);
	display: flex;
	align-items: center;
	margin: 1.75rem auto;
	position: relative;
	width: auto;
	pointer-events: none;
}

.modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #202027;
	background-clip: padding-box;
	border: none;
	border-radius: 10px;
	outline: 0;
}

.modal-header {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 1rem;
	border-bottom: 1px solid #dee2e6;
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
	border-bottom-color: #333;
}

.modal-content .modal-title {
	font-family: 'Montserrat', sans-serif;
	font-size: 22px;
	margin-bottom: 0;
	line-height: 1.5;
}

.btn-custom-closer {
	background: #fff;
	border: none;
	border-radius: 20px;
	padding: 5px;
	color: #000;
	width: 35px;
	height: 35px;
	font-size: 20px;
	line-height: 35px;
	position: absolute;
	right: -12px;
	top: -12px;
	transition: 0.3s;
	z-index: 3;
	cursor: pointer;
}

.btn-custom-closer:hover {
	background: #edcb74;
	color: #fff;
}

.modal-body {
	position: relative;
	flex: 1 1 auto;
	padding: 1rem;
}

.placebid-modal-wrapper .modal-body .placebid-form-box h5.title {
	margin-bottom: 15px;
}

.placebid-modal-wrapper .modal-body .placebid-form-box .bid-content .bid-content-top {
	display: block;
	border: none;
	padding: 0;
}

.placebid-modal-wrapper .modal-body .placebid-form-box .bid-content .bid-content-top .bid-content-left {
	display: flex;
	position: relative;
	align-items: center;
}

.placebid-modal-wrapper .modal-body .placebid-form-box .bid-content .bid-content-top .bid-content-left input {
	background: #01010157;
	height: 40px;
	border-radius: 5px;
	border: 2px solid transparent;
	transition: all 0.4s ease-out 0s;
	color: #fff;
	width: 100%;
	padding: 0 10px;
}

.placebid-modal-wrapper .modal-body .placebid-form-box .bid-content .bid-content-top .bid-content-left span {
	display: block;
	margin-bottom: 10px;
	font-size: 16px;
	right: 15px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	color: #fff;
}

.placebid-modal-wrapper .bid-content-mid {
	padding: 20px 0;
	display: flex;
	justify-content: space-between;
}

.placebid-modal-wrapper .bid-content-mid span {
	display: block;
	margin-bottom: 10px;
	font-size: 16px;
	color: #fff;
}

.box-maxHeight {
	max-height: 200px !important;
}

.coin-img {
	width: 250px;
	margin: 0 auto;
}

#background-video {
	width: 100%;
}

.coin-img img {
	width: 100%;
	-webkit-animation-duration: 2.5s;
	animation-duration: 2.5s;
	animation-direction: alternate-reverse;
	-webkit-animation: swing 2s ease-in-out infinite alternate;
	animation: swing 2s ease-in-out infinite alternate;
}

span {
	line-height: 1.5em;
}


.mtz-profile_box {
	width: 100%;
	position: relative;
	z-index: 999;
}

.faq-area{
	min-height: 100vh;
}

.mtz-header_icon_btn {
	height: 44px;
	width: 160px;
	color: #fff;
	position: relative;
	flex: none;
	transition: all 0.3s ease;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	z-index: 1;
	border: 2px solid transparent;
	background: linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
	padding: 12px 23px;
}

.mtz-header_icon_btn span {
	font-size: 10px;
}

.mtz-toggle_body {
	position: absolute;
	z-index: 11;
	background-color: #fff;
	top: 100%;
	right: 0;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.mtz-profile_box .mtz-toggle_body {
	width: 300px;
	padding: 25px 35px;
	border: 2px solid transparent;
	background: linear-gradient(#000 0 0) padding-box, linear-gradient(to right, #edcb74, #F9CA1A) border-box;
	border-radius: 15px;
	box-shadow: 0 0 10px #edcb74;
}


.mtz-toggle_body.active {
	opacity: 1;
	visibility: visible;
	z-index: 999;
}

.mtz-profile_box .mtz-user_name {
	font-size: 16px;
	font-weight: 400;
	font-family: inherit;
	margin: 0 0 5px 0;
}

.mtz-profile_box .mtz-user_balance {
	font-size: 18px;
	font-weight: 600;
	color: #ff8b2b;
	margin-bottom: 12px;
}

.mtz-user_info span {
	color: #fff;
	margin-bottom: 8px;
	display: block;
}

.mtz-user_address {
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: 500;
	color: #ff8b2b;
	margin-bottom: 5px;
}

.mtz-profile_box ul {
	list-style: none;
	padding: 0;
	flex-direction: column;
}

.mtz-profile_box ul li a,
.mtz-profile_box ul li>div {
	color: #fff;
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 5px 10px;
	position: relative;
	font-family: 'Montserrat', sans-serif;
	font-weight: 500;
}

.mtz-profile_box ul li>div {
	font-size: 14px;
	text-transform: uppercase;
	transition: .4s ease;
	min-height: 44px;
}

.mtz-profile_box ul li>div:hover {
	color: #ff8b2b;
}

.mtz-filter_head_right {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: -5px -7px;
}

.dashrboard-cover {
	height: 300px;
	border-radius: 15px;
	position: relative;
	background-size: cover;
	background-position: center;
}

.mtz-profile_pic img {
	width: 100%;
	height: 100%;
}

.mtz-profile_pic {
	width: 150px;
	height: 150px;
	margin: 0 auto;
	border-radius: 100px;
	box-shadow: 0 0 10px #edcb74;
	position: relative;
	z-index: 1;
	overflow: hidden;
}

.mtz-profile_title {
	margin-top: 20px;
	text-align: center;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	letter-spacing: inherit;
	font-weight: 400;
	position: relative;
	text-shadow: #edcb74 0 0 4px, #edcb74 0 0 4px, #edcb74 0 0 4px, #edcb74 0 0 4px, #edcb74 0 0 4px, #edcb74 0 0 4px;
}

.dashrboard-cover {
	height: 300px;
	border-radius: 15px;
	position: relative;
	background-size: cover;
	background-position: center;
}

.dashboard-page {
    min-height: 80vh;
}

.dashboard-container {
	display: flex;
	gap: 20px;
	align-items: flex-start;
}

.dashboard-sidebar {
	width: 250px;
	border-radius: 10px;
	padding: 30px 10px;
	border: 1px solid #edcb74;
	box-shadow: 0 0 10px #edcb7496;
}

.btn {
    text-transform: capitalize;
}

.mtz-profile_info {
	margin-bottom: 20px;
	border-bottom: 1px solid #fafafa;
}

.mtz-profile_title {
	margin-top: 20px;
	text-align: center;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	letter-spacing: inherit;
	font-weight: 400;
}

.mtz-profile_nav .nav-item {
	width: 100%;
}

.mtz-profile_nav .nav-item .nav-link.active {
	background: #d77b24;
	border-radius: 8px;
	padding: 12px 23px;
}

.mtz-profile_nav .nav-item .nav-link {
	color: #fff;
	border: aliceblue;
	display: flex;
	align-items: center;
	gap: 10px;
	min-height: 51px;
}

.mtz-profile_nav .nav {
	gap: 10px;
}

.dashboard-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-radius: 20px 15px;
	padding: 20px;
	border: 1px solid #edcb74;
	box-shadow: 0 0 10px #edcb7496;
}

.dashboard-content hr{
	margin: 5px 0px;
}

.wallet-box img {
    width: 32px;
	box-shadow: 0 0 10px #edcb74;
	border-radius: 99px;
}

.mtz-icon {
    position: absolute;
    right: 20px;
    top: 30px;
}

.wallet-box {
    z-index: 1;
    position: relative;
	background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
	gap: 20px;
    align-items: stretch;
    padding: 20px;
    margin-top: 30px;
    min-height: 175px;
    border-radius: 10px;
	background: #000;
	border: 1px solid #edcb74;
    box-shadow: 0 0 10px #edcb74;
}

.wallet-box button{
	flex: 0 0 auto;
	width: fit-content;
}

.wallet-box button,
.revenue-wrap .number-item button{
	padding: 5px 10px;
}

.total-wrap{
	flex-direction: column;
}

.money h2 {
    font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	letter-spacing: inherit;
	color: #edcb74 !important;
	font-weight: 500;
}

.text-primary{
	color: #edcb74 !important;
}

.wallet-box .money-wrap {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    justify-content: space-between;
	flex-direction: column;
}

.money-wrap button {
    padding: 3px 10px;
}

.wallet-box>span {
	font-size: 16px;
    margin-bottom: 10px;
    display: block;
}

.btn-import-wrap{
	gap: 10px;
}

.btn-import-wrap .btn,
.money-wrap .btn{
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	letter-spacing: inherit;
	font-weight: 500;
}

.mtz-icon-wrap {
    width: 64px;
    height: 64px;
    position: absolute;
    right: 20px;
    top: 10px;
    border-radius: 99%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
}

.exchange-wrap {
    z-index: 1;
    position: relative;
	border: 1px solid #edcb74;
    box-shadow: 0 0 10px #edcb7496;
    background: #000;
	border-radius: 10px;
	padding: 20px;
}

.exchange-wrap, 
.number-wrap {
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.exchange-item.earning {
    text-align: center;
}

.exchange-icon>div {
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 0;
    text-shadow: #edcb74 0 0 4px, #edcb74 0 0 4px, #edcb74 0 0 4px, #edcb74 0 0 4px, #edcb74 0 0 4px, #edcb74 0 0 4px;
}

.exchange-item .exchange-icon span {
    font-size: 24px;
    color: #fff;
}

.exchange-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 15px!important;
    gap: 15px!important;
}

.exchange-item img {
    width: 32px;
}

.wallet-box h2,
.wallet-box .money span {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: inherit;
    font-size: 16px;
    margin-bottom: 0;
	padding: 3px 8px;
	border-radius: 5px;
	width: 100%;
}

.swap-wrap .info_name {
    grid-gap: 20px;
    gap: 20px;
}
.portfolio-box .info_name {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.portfolio-box .info_portfolio {
	width: 50%;
	margin: 0 auto;
} 

.swap-input-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
	background-color: #000;
	border: 1px solid #edcb74;
    box-shadow: 0 0 10px #edcb7496;
    border-radius: 99px;
    width: 85%;
    padding: 10px;
}


.swap-total-balance {
    color: #fff;
    width: 100%;
    text-align: right;
}

.swap-input-container {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    width: 100%;
	gap: 10px;
}

.swap-input-container button {
    flex: 1 1 auto;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 14px;
    letter-spacing: inherit;
    font-weight: 500;
	padding: 5px 10px;
}

.swap-input-container button:hover {
    color: #edcb74;
}

.swap-input-container button:focus {
    outline: 0px;
    box-shadow: none;
}

.swap-input-wrap input {
    margin-bottom: 0px !important;
    flex: 1 1 auto;
    font-size: 18px !important;
}

.swap-input-icon {
    display: flex;
    align-items: center;
    flex: 0 0 25%;
    color: #fff;
    gap: 10px;
    justify-content: flex-start;
    flex-flow: row nowrap;
    -webkit-box-align: center;
	border-left: 1px solid #fafafa40;
	padding-left: 5px;
}

.swap-input-icon img {
    width: 32px;
    height: 32px;
    object-fit: contain;
    box-shadow: none !important;
    border: 0px !important;
}

.swap-icon {
    width: 42px;
    height: 42px;
    padding: 10px;
    border-radius: 99%;
	box-shadow: 0 0 15px #edcb74;
    border: 1px solid #edcb74;
    color: #fff;
    cursor: pointer;
    transition: .4s ease;
}

.swap-icon:hover {
    transform: translateY(-5px);
    background-color: #000000;
}

.swap-wrap .btn-custom-size {
    width: 75% !important;
}

.portfolio-box .form-control {
    text-align: center;
    background-color: transparent;
    min-height: 46px;
    margin-bottom: 0px;
    color: #fff;
    border: 0;
    font-size: 24px;
}

.portfolio-box .form-control:focus {
    outline: none;
    box-shadow: none;
}

.enable-wrap{
	gap: 10px;
}

.fs-btn-wrap {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
    text-transform: capitalize;
}

.portfolio-box .connect-group>.d-flex {
	border: 1px solid #edcb74;
    /* box-shadow: 0 0 10px #edcb7496; */
    padding: 2px 5px;
    border-radius: 8px;
    margin-bottom: 10px;
    color: #fff;
}

.portfolio-box .connect-group>.d-flex span{
	font-size: 12px;
}

.portfolio-box .connect-group>.d-flex span:last-child {
    background: linear-gradient(96.41deg,#edcb74 11%,#edcb74 44.81%,#edcb74 96.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
}

.enable-wrap .btn{
	font-family: 'Montserrat', sans-serif !important;
    font-size: 14px;
    letter-spacing: inherit;
    font-weight: 500;
}

.mtz-btn-wrap {
    display: flex;
    align-items: center;
	justify-content: center;
    gap: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.number-wrap {
    display: grid;
    grid-template-columns: repeat(2,minmax(0,1fr));
	justify-content: stretch;
}

.exchange-item, 
.number-wrap .number-item {
    padding: 15px 0;
    flex: 1 1 auto;
    text-align: center;
}

.number-wrap .number-item img {
    width: 32px;
	box-shadow: 0 0 10px #edcb74;
    border-radius: 99%;
}

.number-wrap .number-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
    position: relative;
	background: #000;
    border: 1px solid #edcb74;
    box-shadow: 0 0 10px #edcb74;
	border-radius: 10px;
	align-self: stretch;
	background-size: cover;
    min-height: 118px;
}

.number-wrap .number-item h2 {
    color: #fff;
    min-height: 50px;
    max-width: 90%;
    overflow-x: scroll;
    margin: 0 auto;
    display: flex;
	padding: 0 5px;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
	font-weight: 400;
	font-family: 'Montserrat', sans-serif !important;
}

.number-wrap .number-item h2::-webkit-scrollbar {
	display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .number-wrap .number-item h2 {
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
  }

.earning h2 span {
    text-shadow: #edcb74 0 0 4px, #edcb74 0 0 4px, #edcb74 0 0 4px, #edcb74 0 0 4px, #edcb74 0 0 4px, #edcb74 0 0 4px;
    color: #fff;
	line-height: inherit;
	font-weight: 400;
}

.portfolio-box .info_name .name {
    margin-top: 20px;
    justify-content: center;
}

.info_name .name {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.connect-input-wrap {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;
    margin-bottom: 20px;
    padding: 5px;
    border: 1px solid #edcb74;
    border-radius: 10px;
}

.connect-icon {
    width: 32px;
}

.portfolio-box .mtz-icon {
    position: relative;
    top: unset;
    right: unset;
	width: 32px;
	height: 32px;
    object-fit: contain;
    box-shadow: 0 0 15px #edcb74;
    border: 1px solid #edcb74;
    border-radius: 99%;
}

.connect-group {
    margin-top: 20px;
    grid-gap: 20px;
    gap: 20px;
}

.portfolio-box .connect-group {
    width: 100%;
}

.portfolio-box .info_name label {
    background: linear-gradient(96.41deg,#edcb74 11%,#edcb74 44.81%,#edcb74 96.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    margin-bottom: 10px;
    font-size: 20px;
	font-weight: 500;
}

.table-bordered td, 
.table-bordered th{
	border: 1px solid #2f2f2f;
}

.modal {
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
}

.modal-connect .modal-content {
    border-radius: 10px;
    background: #000;
    box-shadow: 0 0 10px #edcb74;
    border: 0;
}

.modal-dialog {
    max-width: 800px;
}

.modal-title {
    color: #fff;
    font-family: "Saira";
    letter-spacing: inherit;
    background: linear-gradient(96.41deg,#edcb74 11%,#edcb74 44.81%,#edcb74 96.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.modal-connect .modal-content .close {
    border-radius: 99px;
    padding: 5px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 15px #edcb74;
    border: 1px solid #edcb74;
    background-color: transparent;
    color: #fff!important;
	margin: 0;
	float: none;
}

.modal-connect .modal-content .close img {
    width: 12px!important;
}

.modal-header{
	position: relative;
}

.history-detail-wrap .table-bordered>:not(caption)>*>*, 
.portfolio-table-wrap .table-bordered>:not(caption)>*>* {
    vertical-align: middle;
    text-align: center;
}

.claimed, .not-claim {
    padding: 5px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    margin: 0 auto;
}

.not-claim {
    border-radius: 99px;
    box-shadow: 0 0 15px #dc3545;
    border: 1px solid #dc3545;
    color: #dc3545!important;
}

.mtz-wallet {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;
    text-align: center;
    margin-top: 15px;
	margin-bottom: 15px;
    color: #fff;
	cursor: pointer;
}

.mtz-profile_title{
	font-size: 20px;
	font-weight: 500;
}

.revenue-wrap .number-item{
	gap: 10px;
}

.portfolio-box h2{
    box-shadow: none !important;
	border: 0px;
	text-align: center;
	font-size: 20px;
}

.head-filter{
	gap: 10px;
}

.referral-box-info {
    margin-top: 20px;
    color: #fff;
}

.referral-box-title {
    margin-bottom: 10px;
    display: block;
    font-size: 18px;
}

.referral-box-info .name {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    padding: 10px 20px;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    border: 1px solid #edcb74;
    border-radius: 8px;
    box-shadow: 0 0 10px #edcb74;
}

.referral-box-info .name span {
    word-wrap: break-word;
	color: #edcb74;
}

.referral-box-info .name svg {
    color: #edcb74;
    cursor: pointer;
}

.rule-wrap thead tr {
    background-color: #edcb74;
}

.card-content .btn {
    padding: 5px 10px;
    margin-top: 10px;
}

.mtz-profile_box .mtz-user_balance {
    word-break: break-word;
}

.mtz-profile_box .mtz-user_balance {
	font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: #ff8b2b;
    margin-bottom: 12px;
}

.mtz-profile_box .mtz-user_balance:not(:last-child) {
	padding-bottom: 5px;
    font-size: 18px;
	border-bottom: 1px solid #eceff8;
}

.mtz-profile_box .mtz-user_balance img {
    width: 24px;
}

.portfolio-nav {
    justify-content: center;
    gap: 20px;
}

.portfolio-nav .nav-link.active {
    background: #000;
    box-shadow: 0 0 15px #edcb74;
    border: 1px solid #edcb74;
}

.portfolio-nav .nav-link {
    font-size: 20px;
    color: #fff;
    border-radius: 99px;
    border: 1px solid #edcb74;
}

.portfolio-nav .nav-link:hover {
    box-shadow: 0 0 15px #edcb74;
    border: 1px solid #edcb74;
}

.portfolio-nav .nav-item {
    flex: 1;
    text-align: center;
}

.portfolio-nav .nav-link span {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.modal {
    backdrop-filter: blur(12px);
}

.modal-connect .modal-content .close {
    border-radius: 99px;
    padding: 5px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 15px #edcb74;
    border: 1px solid #edcb74;
    background-color: transparent;
    color: #ffffff !important;
}

.modal-connect .modal-content .close img{
    width: 12px !important;
}

.modal-connect .modal-content .close:hover {
    transform: scale(1.1);
}

.switch_wallet_network {
    display: flex;
    align-items: center;
    justify-content: center;
	gap: 30px;
}

.switch_network_item {
    cursor: pointer;
    text-align: center;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    padding: 20px 8px;
    transition: all .25s linear;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
	border-width: 1px;
    border-style: solid;
    border-color: #edcb74;;
	flex: 1;
}

.switch_network_item:before, 
.switch_network_item:after {
    content: '';
    display: block;
    position: absolute;
    border-color: #edcb74;
    box-sizing: border-box;
    border-style: solid;
    width: 1em;
    height: 1em;
    transition: all 0.3s ease-in-out;
}

.switch_network_item:before {
    top: -6px;
    left: -6px;
    border-width: 1px 0 0 1px;
    z-index: 5;
}

.switch_network_item:after {
    bottom: -6px;
    right: -6px;
    border-width: 0 1px 1px 0;
}

.switch_network_item:hover:before, 
.switch_network_item:hover:after {
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    border-color: #edcb74;
}

.switch_network_item span{
	transition: all .25s;
}

.switch_network_item:hover span{
	color: #edcb74;
}

.switch_network_item img {
	padding: 10px;
    width: 70px;
    height: 70px;
	box-shadow: 0 0 10px #edcb74;
	border-radius: 99px;
    object-fit: contain;
	margin-bottom: 10px;
	transition: all .4s;
}

.switch_network_item:hover img{
	transform: scale(1.1);
}

.switch_wallet_network .switch_network_item:last-child {
    margin-bottom: 0;
}


.react-toast-notifications__toast {
    background: linear-gradient(to right, #edcb74, #F9CA1A);
    box-shadow: 0 0 10px #edcb74 !important;
}

.react-toast-notifications__toast__content {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-toast-notifications__toast__dismiss-button {
    color: #fff;
    opacity: 1;
    display: flex;
    align-items: center;
}

.react-toast-notifications__toast__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    background: #edcb74 !important;
}

.money-wrap.d-grid {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    flex-direction: inherit;
}

.video-list {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 30px;
	margin-bottom: 50px;
}

.video-item {
    border: 1px solid;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #edcb7499;
    box-shadow: 0 0 10px #edcb7499;
}

.video-item .video-bottom {
    padding: 10px;
    text-align: center;
}

.video-item .video-bottom h3 {
    font-size: 20px;
	font-family: 'Montserrat', sans-serif;
	letter-spacing: inherit;
}

body>iframe{
	display: none !important;
}

iframe#widget2,
.video-lightbox iframe{
	display: block !important;
}

.card-badge {
    font-size: 11px;
    position: absolute;
    border-bottom: 30px solid #4d9c40;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    height: 0;
    width: 121px;
    text-align: center;
    transform: rotate(-45deg);
    left: -45px;
    top: 1px;
    color: #fff;
    line-height: 2.4em;
}

.card-badge::before {
    content: "";
    border-bottom: 16px solid #2d6824;
    border-left: 16px solid transparent;
    transform: rotate(45deg);
    left: -27px;
    top: 22px;
    position: absolute;
}

.card-badge::after {
    content: "";
    border-bottom: 16px solid #2d6824;
    border-left: 16px solid transparent;
    transform: rotate(45deg);
    left: 72px;
    top: 22px;
    position: absolute;
}

.common{
	border-bottom-color: #78abcc;
}

.common:before,
.common:after{
	border-bottom-color: #78abcc8c;
}

.uncommon{
	border-bottom-color: #278cce;
}

.uncommon:before,
.uncommon:after{
	border-bottom-color: #278cce7a;
}

.rare{
	border-bottom-color: #BE2E8F;
}

.rare:before,
.rare:after{
	border-bottom-color: #be2e8f6e;
}

.epic{
	border-bottom-color: #ED322E;
}

.epic:before,
.epic:after{
	border-bottom-color: #ed322e63;
}

.card-aff {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(50%,rgba(255,255,255,.07)),color-stop(120%,rgba(255,255,255,0)));
    background: -o-linear-gradient(top,rgba(255,255,255,.07) 50%,rgba(255,255,255,0) 120%);
    background: linear-gradient(180deg,rgba(255,255,255,.07) 50%,rgba(255,255,255,0) 120%);
    border-radius: 7px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 15px;
    padding: 15px;
    position: relative;
	border: 1px solid rgb(237 203 116);
}

.card-aff .card-header {
    border-bottom: 1px solid rgba(255,255,255,.1);
    margin-bottom: 15px;
    padding: 0px 0px 15px 0px;
}

.card-aff .card-body{
	padding: 0px;
}

.card-aff .desc {
    width: 100%;
    text-align: left;
    font-size: 14px;
}

.card-aff .title {
    font-size: 16px;
    margin-bottom: 10px;
    color: #d37226;
}

.card-body .form-group{
	margin-bottom: 0px;
}

.card-body .form-group label {
    color: #d37226;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: .5px;
    text-transform: uppercase;
}

.card-body .form-group .check {
    background: -webkit-gradient(linear,left top,left bottom,color-stop(35%,rgba(12,7,10,.35)),color-stop(120%,rgba(255,255,255,0)));
    background: -o-linear-gradient(rgba(12,7,10,.35) 35%,rgba(255,255,255,0) 120%);
    background: linear-gradient(rgba(12,7,10,.35) 35%,rgba(255,255,255,0) 120%);
    border-radius: 7px;
    -webkit-box-shadow: inset 0 0 20px rgba(12,7,10,.4), inset 0 -5px 10px rgba(12,7,10,.5);
    box-shadow: inset 0 0 20px rgba(12,7,10,.4), inset 0 -5px 10px rgba(12,7,10,.5);
    width: 100%;
    border: 2px solid rgba(255,255,255,.02);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #ffffff;
    font-size: 10px;
    font-weight: 400;
    height: 60px;
    outline: 0;
    padding: 0 10px;
    -webkit-transition: border-color .3s;
    -o-transition: border-color .3s;
    transition: border-color .3s;
	white-space: nowrap;
	overflow: hidden;
	display: flex;
	align-items: center;
}

.card-body .form-group .check span{
	white-space: break-spaces;
	overflow: hidden;
}

.action.action--copy {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 3px;
    bottom: 15px;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 30px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    width: 30px;
}

.form-group {
    position: relative;
}

.stat {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
}

.stat .cell {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(50%,rgba(255,255,255,.07)),color-stop(120%,rgba(255,255,255,0)));
    background: -o-linear-gradient(top,rgba(255,255,255,.07) 50%,rgba(255,255,255,0) 120%);
    background: linear-gradient(180deg,rgba(255,255,255,.07) 50%,rgba(255,255,255,0) 120%);
    border-radius: 7px;
    border: 1px solid rgb(237 203 116);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 20px 10px;
    position: relative;
    text-align: center;
    width: 33.3333333333%;
}

.stat .cell .desc {
    color: #fff;
    font-size: 9px;
    letter-spacing: .5px;
    line-height: 12px;
    margin-top: 7px;
    text-transform: uppercase;
}

.text .title {
    font-size: 12px;
    margin-bottom: 0;
    text-transform: inherit;
}

.text .desc {
    font-size: 10px;
}

.step {
    margin-bottom: 13px;
}

.how {
    margin-bottom: 15px;
}

.detail .d-flex div:first-child {
    color: #fff;
    font-size: 12px;
    line-height: 1.5em;
    padding-right: 10px;
    z-index: 1;
}

.detail .d-flex span {
    display: block;
    position: relative;
    width: calc(100% - 20px);
}

.detail .d-flex span::before {
    content: "";
    background: url(https://gemly.gg/public/image/game/other/dashed.png?h=ade792ed25f76e86c9f78525cbd983b4) left center repeat-x;
    height: 2px;
    opacity: .2;
    position: absolute;
    top: 13px;
    width: 100%;
}

.detail .d-flex div:last-child {
	background: -webkit-gradient(linear,left top,left bottom,color-stop(50%,rgba(255,255,255,.03)),color-stop(120%,rgba(255,255,255,0)));
	background: -o-linear-gradient(top,rgba(255,255,255,.03) 50%,rgba(255,255,255,0) 120%);
	background: linear-gradient(180deg,rgba(255,255,255,.03) 50%,rgba(255,255,255,0) 120%);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	border-radius: 2px;
	color: #fff;
	cursor: default;
	display: inline-block;
	font-size: 13px;
	margin: 0 0 0 10px;
	padding: 6px 10px;
	position: relative;
}

.ref-wrap {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ref-wrap .col-12{
	border: 2px solid transparent;
    background: linear-gradient(#000 0 0) padding-box, linear-gradient(to right, #edcb74, #F9CA1A) border-box;
    border-radius: 15px;
    box-shadow: 0 0 10px #edcb74;
	padding: 20px;
}

.aff-address{
	color: #edcb74;
}

.aff-address {
    margin: 10px 0;
    background-color: #1e1a13;
    display: inline-flex;
    padding: 10px;
    border-radius: 10px;
	font-size: 14px;
}

.btn-document {
    background-color: #edcb74;
    width: 200px;
    min-height: 60px;
	margin-top: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
	box-shadow: 0 0 10px #edcb74;
}

.btn-document:hover{
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    -webkit-animation: slideUp 500ms ease forwards;
    animation: slideUp 500ms ease forwards;
    color: #f0eb8d;
    background: linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to right, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to left, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 0 0, linear-gradient(to bottom, #f0eb8d 0.2rem, transparent 0.2rem) 100% 0, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 0 100%, linear-gradient(to top, #f0eb8d 0.2rem, transparent 0.2rem) 100% 100%;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
}

@media (max-width: 767px) {

	.btns {
		flex-direction: column;
	}

	.video-list {
		grid-template-columns: repeat(1,1fr);
	}
	
	.text-follow {
		text-align: left;
	}

	.box-btn {
		flex-direction: column;
	}

	.banner-title {
		font-size: 48px;
	}

	.title {
		font-size: 32px;
	}

	.banner-subtitle {
		font-size: 20px;
	}

	.whatismetazombie-box-wrap {
		margin-bottom: 30px;
	}

	.benefit-box-wrap {
		margin-bottom: 30px;
	}

	ul.tabs-vr-head {
		flex-direction: column;
	}

	ul.tabs-vr-head li {
		min-width: 90%;
	}

	.vision-card-wrap,
	.about-team-item-three {
		grid-template-columns: auto;
	}

	.about-team-item-four {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	.about-team-item img {
		width: 120px;
	}

	.character-tab-wrap {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	.tab-text {
		width: 100%;
	}

	.list-community {
		flex-direction: column;
	}

	.list-community .btn {
		min-width: 75%;
	}

	.copyright-left {
		flex-direction: column;
	}

	.world-tabs {
		position: relative;
		left: unset;
		top: unset;
		width: 100%;
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	.world-tab-content {
		flex-direction: column;
	}

	.world-tab-right {
		order: 1;
		flex: 1;
	}

	.world-tab-left {
		order: 2;
		flex: 1;
		min-height: unset;
	}

	.world-tab-center {
		order: 3;
	}

	.characters-circle-shape h1 {
		font-size: 12rem;
		top: 10%;
	}

	.whatismetazombie-circle-shape h1,
	.about-circle-shape h1 {
		font-size: 3rem;
		top: 10%;
	}

	.desc {
		width: 100%;
	}

	.page-comic-text img {
		height: 100% !important;
		object-fit: contain;
	}

	.page {
		top: 0 !important;
		padding: 0;
	}

	.page .page-comic-content {
		align-items: center;
		justify-content: center;
		align-self: stretch;
	}

	.demo-book {
		min-height: 240px !important;
		overflow: hidden;
	}

	.video-react .video-react-big-play-button.video-react-big-play-button-center {
		transform: translate(-50%, -50%) !important;
		margin: 0 !important;
	}

	.box-connect-wrap,
	.box-history-wrap {
		padding: 15px;
	}

	.box-connect-head {
		flex-direction: column;
		align-items: flex-start;
	}

	.box-connect-head-left span {
		word-break: break-all;
	}

	.table td {
		word-break: break-all;
	}

	.w-75 {
		width: 100% !important;
	}

	.market-list,
	.mynft-list {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	.btn-right {
		text-align: left;
	}

	.filter-wrapper {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	.nft-style-item {
		padding: 5px;
	}

	.nft-style-item a {
		font-size: 16px;
		text-align: center;
	}

	.nft-style-item .bid-react-area {
		justify-content: center;
	}

	.box-maxHeight {
		max-height: 400px !important;
	}

	.dashboard-container {
		flex-direction: column;
	}
	
	.dashboard-sidebar {
		width: 100%;
	}
	
	.number-wrap {
		grid-template-columns: repeat(1,minmax(0,1fr));
	}
	
	.dashboard-content {
		width: 100%;
		padding: 10px 0px;
	}

	.dashboard-container {
		flex-direction: column;
	}
	
	.dashboard-sidebar {
		width: 100%;
	}
	
	.number-wrap {
		grid-template-columns: repeat(1,minmax(0,1fr));
	}
	
	.dashboard-content {
		width: 100%;
	}
	
	.swap-input-icon span {
		display: none;
	}
	
	.swap-input-wrap {
		width: 100%;
	}
	
	.swap-input-container button {min-width: auto;}
	
	.swap-input-container {
		justify-content: space-between;
		gap: 5px;
	}
	
	.swap-input-icon {
		flex: 1 1 auto;
	}

	.dashboard-container {
		flex-direction: column;
	}
	
	.dashboard-sidebar {
		width: 100%;
	}
	
	.number-wrap {
		grid-template-columns: repeat(1,minmax(0,1fr));
	}
	
	.dashboard-content {
		width: 100%;
	}
	
	.swap-input-icon span {
		display: none;
	}
	
	.swap-input-wrap {
		width: 100%;
	}
	
	.swap-input-container button {
		min-width: auto;
	}
	
	.swap-input-container {
		justify-content: space-between;
		gap: 5px;
	}
	
	.swap-input-icon {
		flex: 1 1 auto;
	}
	
	.wallet-box .money-wrap {
		flex-direction: column;
		align-items: flex-start;
	}
	
	.btn-import-wrap .btn, .money-wrap .btn {
		min-width: auto;
	}

	.menu-flex-mobile>ul>li:not(:last-child) {
		display: none;
	}

	.mtz-profile_box ul li>div {
		padding: 11px 20px;
		line-height: inherit;
		font-size: 12px;
	}

	.mtz-header_icon_btn {
		height: auto;
		padding: 8px 10px;
	}

	.page-title-wrap{
		min-height: 250px !important;
	}

	hr{
		margin: 10px auto;
	}

	.wallet-box::after,
	.number-wrap .number-item:after{
		display: none;
	}

	.modal-dialog {
		padding: 15px;
	}

	.btn-connectWallet{
		font-size: 11px;
	}

	.guns_slider_thumb .swiper-slide .latest-games-items{
		padding: 7px;
	}

	.guns-slider .swiper-slide{
		height: auto;
	}

	a.social-item {
		width: 30%;
		clip-path: initial;
	}

	.stat{
		flex-direction: column;
	}

	.stat .cell{
		width: 100%;
	}

}